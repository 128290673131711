import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import styles from './styles';
import { isTimeSlotAvailable } from '../../api/services';

const useStyles = makeStyles(styles());

type Props = {
    storeId: Number,
    startHour: String,
    endHour: String,
    numberPeople: Number,
}

export default function TimeSlotCell(props:Props) {
    const classes = useStyles();
    const [slotState, setSlotState] = useState(classes.available);
    const {
        startHour, endHour, storeId, numberPeople
    } = props;

    useEffect(() => {
        isTimeSlotAvailable(storeId, startHour, endHour, numberPeople).then((val) => {
            if (val) {
                setSlotState(classes.available);
            } else {
                setSlotState(classes.unavailable);
            }
        });
    }, [startHour, numberPeople]);

    return (
        <ListItem divider classes={{ root: slotState }}>
            <ListItemText
                secondary={startHour.split(' ')[1]}
            />
        </ListItem>
    );
}
