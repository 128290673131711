import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import MessageIcon from '@material-ui/icons/Message';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ServicesHeadTitle from '../ServicesHeadTitle';

import { readNotifications, allowedNotifications } from '../../api/services';

export default class Index extends Component {
    constructor() {
        super();
        this.state = {
            apiNotification: [],
            userId: '',
            open: '',
            orderDetails: [],
        };
        this.notificationList = this.notificationList.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const id = array[array.length - 1];
        this.setState({ userId: id });
        const userNotifications = { userId: id };

        allowedNotifications(userNotifications).then((response) => {
            this.setState({ apiNotification: response });
        });
    }

    handleClick = (id) => {
        const { userId, open } = this.state;
        readNotifications(id).then((response) => {
            const orderDetails = response;
            allowedNotifications({ userId }).then((data) => {
                let choice = '';
                if (open !== id) {
                    choice = id;
                }
                this.setState({
                    apiNotification: data,
                    open: choice,
                    orderDetails,
                });
            });
        });
    }

    buildOrderProducts = (orderProductsDetails) => {
        const result = [];
        orderProductsDetails.forEach((val) => {
            result.push(
                <Grid item xs={12}>
                    <Typography variant='body2'>
                        {`${val.product} (${val.quantity})`}
                    </Typography>
                </Grid>
            );
        });
        return result;
    }

    buildOrderDetaisl = (orderDetails) => (
        <List component='div' disablePadding>
            <ListItem>
                <ListItemText primary='Details of order' />
            </ListItem>
            <ListItem>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            {`Reference: ${orderDetails.reference}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            {`Store: ${orderDetails.store}`}
                        </Typography>
                    </Grid>
                    {orderDetails.service != null ? (
                        <Grid item xs={12}>
                            <Typography variant='body2'>
                                {`Service: ${orderDetails.service.name}`}
                            </Typography>
                        </Grid>
                    ) : ''}
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            {`Type: ${orderDetails.type}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            {`State: ${orderDetails.state}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            {`Total: ${orderDetails.total}`}
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <ListItem>
                <List>
                    <ListItemText primary='Order Products' />
                    <ListItem>
                        <Grid container spacing={1}>
                            {this.buildOrderProducts(orderDetails.products)}
                        </Grid>
                    </ListItem>
                </List>
            </ListItem>
        </List>
    )

    notificationList = (dataNotification) => {
        const result = [];
        const { open, orderDetails } = this.state;
        // const notificationOrderDetails = this.buildOrderDetaisl(orderDetails);
        if (dataNotification.length > 0) {
            // const index = 1;
            dataNotification.forEach((obj) => {
                const date = new Date(obj.created_at);
                result.push(
                    <div>
                        <ListItem
                            key={obj.id}
                            id={obj.id}
                            selected={!!obj.viewed}
                            onClick={() => this.handleClick(obj.id)}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <MessageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={obj.description}
                                secondary={date.toDateString()}
                            />
                        </ListItem>
                        <Collapse in={open === obj.id} timeout='auto' unmountOnExit>
                            <Box fontWeight='fontWeightLight' fontStyle='normal' fontSize='12px' color='text.secondary'>
                                {open === obj.id ? () => this.buildOrderDetaisl(orderDetails) : ''}
                            </Box>
                        </Collapse>
                    </div>
                );
            });
        } else {
            result.push(
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    minHeight='100vh'
                >
                    <Typography>
                        No Notifications found
                    </Typography>
                </Box>
            );
        }
        return result;
    }

    render() {
        const { apiNotification } = this.state;
        return (
            <div>
                <Box pl={1} pt={2}>
                    <ServicesHeadTitle
                        client='Cabanas Nature Resort'
                    />
                </Box>
                { this.notificationList(apiNotification) }
            </div>
        );
    }
}
