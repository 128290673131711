import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import colors from '../../helpers/colors';

/**
 * Centered Loading based on CircularProgress
 */
const LoadingIndicator = () => (

    <Box
        bgcolor={colors.secondary}
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
    >
        <CircularProgress size={50} style={{ color: colors.primary }} />
    </Box>
);

export default LoadingIndicator;
