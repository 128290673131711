import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { FaPaypal, FaConciergeBell } from 'react-icons/fa';
import { BiCreditCard } from 'react-icons/bi';
import styles from './styles';
import colors from '../../helpers/colors';

const useStyles = makeStyles(styles());

type Props = {
    type: string,
    paymentMethod: Function
}

export default function CardSelect(props: Props) {
    const { paymentMethod, type } = props;
    const classes = useStyles();

    if (type === 'paypal') {
        return (
            <IconButton
                className={classes.root}
                onClick={paymentMethod}
            >
                <FaPaypal style={{ paddingTop: '2em' }} color={colors.primary} size={30} />
            </IconButton>
        );
    }
    if (type === 'creditCard') {
        return (
            <IconButton
                className={classes.root}
                onClick={paymentMethod}
            >
                <BiCreditCard style={{ paddingTop: '2em' }} color={colors.primary} size={30} name='creditCard' />
            </IconButton>
        );
    }
    return (
        <IconButton
            className={classes.root}
            onClick={paymentMethod}
        >
            <FaConciergeBell style={{ paddingTop: '2em' }} color={colors.primary} size={30} />
        </IconButton>
    );
}
