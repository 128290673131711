const styles = () => ({

    media: {
        height: 120,
        width: '100%',
    },

});

export default styles;
