import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import 'fontsource-roboto';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import { getCart } from '../../api/services';
import StoreCheckout from '../StoreCheckout';
import CartButton from '../../components/CartButton';
import GoBackBtn from '../../components/goBackButton';
import colors from '../../helpers/colors';
import Offers from '../Offers';
import Vouchers from '../Vouchers';

type Props = {
    title: string,
}

export default class Index extends Component<Props> {
    constructor() {
        super();

        this.state = {
            cartLength: 0,
            checkout: false,
            tab: 0,
            showGoBack: false,
            show: true,
        };
        this.handleCheckout = this.handleCheckout.bind(this);
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        getCart(userId).then((response) => {
            this.setState({ cartLength: response.length });
        });
    }

    handleCheckout() {
        this.setState({ checkout: true, showGoBack: false });
    }

    goBackBtn() {
        const { showGoBack } = this.state;
        if (showGoBack) {
            return (
                <Grid
                    item
                    xs={6}
                    md={6}
                >
                    <GoBackBtn
                        goBackHandler={() => this.setState({
                            show: false,
                            showGoBack: false
                        })}
                    />
                </Grid>
            );
        }
        return (
            <Grid
                item
                xs={6}
                md={6}
            />
        );
    }

    render() {
        const { title } = this.props;
        const {
            cartLength, checkout, tab, show
        } = this.state;

        const handleChange = (event, val) => {
            this.setState({ tab: val, showGoBack: false });
        };

        if (checkout) {
            return (
                <StoreCheckout
                    type='Shopping'
                    goBackOneStep={() => this.setState({ checkout: false })}
                />
            );
        }

        return (
            <div>
                <ServicesHeadTitle client='Cabanas Nature Resort' title={title} />
                <Box>
                    <Grid
                        container
                        justify='center'
                        alignItems='center'
                        spacing={2}
                    >
                        {this.goBackBtn()}
                        <Grid
                            item
                            xs={6}
                            md={6}
                        >
                            <Box display='flex' flexDirection='row-reverse'>
                                <CartButton
                                    qty={cartLength}
                                    handleCheckout={this.handleCheckout}
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <Tabs
                                value={tab}
                                onChange={handleChange}
                                TabIndicatorProps={
                                    {
                                        style: {
                                            backgroundColor: colors.primary
                                        }
                                    }
                                }
                            >
                                <Tab label='Offers' />
                                <Tab label='Vouchers' />
                            </Tabs>
                        </Grid>
                        {tab === 0
                            ? (
                                <Offers
                                    handleCartLength={(val) => this.setState({ cartLength: val })}
                                    handleShowGoBack={() => this.setState({ showGoBack: true })}
                                    show={show}
                                    handleShow={(val) => this.setState({ show: val })}
                                />
                            )
                            : (
                                <Vouchers
                                    handleCartLength={(val) => this.setState({ cartLength: val })}
                                    handleShowGoBack={() => this.setState({ showGoBack: true })}
                                    show={show}
                                    handleShow={(val) => this.setState({ show: val })}
                                />
                            )}
                    </Grid>
                </Box>

            </div>
        );
    }
}
