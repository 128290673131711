import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import PeopleIcon from '@material-ui/icons/People';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import Button from '../../components/Button';
import { reservation } from '../../api/services';
import GoBackBtn from '../../components/goBackButton';
import TimeSlotCalendar from '../../components/TimeSlotCalendar';

type Props = {
    goBackOneStep: Function,
    goBack: Function,
    shop: Array,
    service: Array,
}

export default class Index extends Component<Props> {
    constructor() {
        super();
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1 >= 10 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`;
        const day = currentDate.getDate() >= 10 ? currentDate.getDate() : `0${currentDate.getDate()}`;
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        this.state = {
            dateTime: `${year}-${month}-${day}T${hours}:${minutes}`,
            numberUsers: 1,
            open: false,
            dialogTitle: '',
            dialogContent: '',
            userId: 0,
            clientId: 0,
            room: 0,
        };
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        const clientId = array[array.length - 2];
        const room = array[array.length - 3];
        this.setState({
            userId,
            clientId,
            room
        });
    }

    /**
     * Submit
     */
    submitReservation = (event: Object) => {
        event.preventDefault();

        const { dateTime, numberUsers } = this.state;

        if (dateTime && numberUsers) {
            const diff = moment(dateTime).diff(moment());
            if (diff < 0) {
                this.setState({ open: true, dialogTitle: 'Warning', dialogContent: 'Invalid Date' });
                // alert('invalid date');
            } else if (numberUsers <= 0) {
                this.setState({ open: true, dialogTitle: 'Warning', dialogContent: 'Invalid Number of users' });
                // alert('invalid number of users');
            } else {
                this.sendReservation(dateTime, numberUsers);
            }
        } else {
            this.setState({ open: true, dialogTitle: 'Warning', dialogContent: 'Date cannot be blank' });
            // alert('data cannot be blank');
        }
    }

    /**
     *  Send Reservation
     */
    sendReservation = (dateTime: string, numberUsers: number) => {
        const { shop, service } = this.props;
        const { userId, clientId, room } = this.state;

        const data = {
            shop: shop.id,
            numberUsers,
            service: service.id,
            user: userId,
            client: clientId,
            room,
            dateTime,
        };

        reservation(data).then((response) => {
            if (response.id) {
                this.setState({ open: true, dialogTitle: 'Success', dialogContent: 'Your reservation has been completed' });
            } else if (response.unavailable.workingHours) {
                this.setState({ open: true, dialogTitle: 'Warning', dialogContent: 'Reservation date is outside of store working hours' });
            } else if (response.unavailable.serviceCapacity) {
                this.setState({ open: true, dialogTitle: 'Warning', dialogContent: `Service capacity is only ${response.unavailable.serviceCapacity} please reduce the number of people in order to make the reservation` });
            } else if (response.unavailable.suggestedStores) {
                const content = [];
                content.push('There are no available slots at this reservation date we suggest you pick another hour or you can switch to one the suggested stores:');
                response.unavailable.suggestedStores.forEach((element) => {
                    content.push(
                        <>
                            <br />
                            {element.name}
                        </>
                    );
                });
                this.setState({ open: true, dialogTitle: 'Warning', dialogContent: content });
            }
        });
    }

    simpleDialog = () => {
        const { goBack } = this.props;
        const { open, dialogContent, dialogTitle } = this.state;
        const handleClose = () => {
            this.setState({ open: false });
            if (dialogTitle === 'Success') {
                setTimeout(goBack(), 5000);
            }
        };

        return (
            <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} fullWidth>
                <DialogTitle id='customized-dialog-title' onClose={handleClose}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent dividers>
                    {dialogContent}
                </DialogContent>
            </Dialog>
        );
    }

    render() {
        const { service, goBackOneStep, shop } = this.props;
        const { numberUsers, dateTime } = this.state;
        return (
            <div>
                <ServicesHeadTitle
                    client='Cabanas Nature Resort'
                    title={'Booking '.concat(shop.name)}
                    subtitle={service.name}
                />
                <GoBackBtn goBackHandler={goBackOneStep} />

                <Box p={3}>
                    <form onSubmit={this.submitReservation}>

                        <Grid
                            container
                            justify='center'
                            alignItems='center'
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                md={12}
                            >
                                <TimeSlotCalendar storeId={shop.id} numberUsers={numberUsers} date={dateTime.split('T')[0]} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                            >
                                <TextField
                                    id='numbeUsers'
                                    label='Number People'
                                    type='number'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'><PeopleIcon /></InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => this.setState(
                                        { numberUsers: event.target.value }
                                    )}
                                    defaultValue={numberUsers}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                            >
                                <TextField
                                    id='datetime-local'
                                    label='Reservation Date Time'
                                    type='datetime-local'
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => this.setState(
                                        { dateTime: event.target.value }
                                    )}
                                    defaultValue={dateTime}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Button
                                    text='Make Reservation'
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                {this.simpleDialog()}
            </div>
        );
    }
}
