import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getCartItens, deleteItemCart, clearCart } from '../../api/services';
import LoadingIndicator from '../../components/LoadingIndicator';
import CheckoutButton from '../../components/checkoutButton';
import GoBackBtn from '../../components/goBackButton';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import Payment from '../Payment';

type Props = {
    type: String,
    goBackOneStep: Function,
    goBack: Function
}

export default class Index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            selected: false,
            apiDataCart: false,
            cartPrice: 0,
            userId: 0,
        };
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        this.setState({ userId });
        getCartItens(userId).then((response) => {
            this.setState({ apiDataCart: response });
            this.getTotalprice(response);
        });
    }

    getTotalprice = (cart) => {
        let totalPrice = 0;
        cart.forEach((item) => {
            const finalPrice = this.getPrice(item.item, item.type);
            totalPrice += finalPrice;
        });
        this.setState({ cartPrice: totalPrice });
    }

     getPrice = (item, type) => {
         let result;
         if (type === 'voucher' || item.discount === 0 || item.discount === null) {
             result = item.price;
         } else {
             const price = item.price - item.price * (item.discount / 100);
             result = price;
         }
         return result;
     }

     deleteFromCart = (id) => {
         const { userId } = this.state;
         deleteItemCart(id).then(() => {
             getCartItens(userId).then((response) => {
                 this.setState({ apiDataCart: response });
                 this.getTotalprice(response);
             });
         });
     }

     clearUserCart = (apiDataCart) => {
         if (apiDataCart.length > 0) {
             const array = window.location.href.split('/');
             const userId = array[array.length - 1];
             clearCart(userId).then(() => {
                 getCartItens(userId).then((response) => {
                     this.setState({ apiDataCart: response });
                     this.getTotalprice(response);
                 });
             });
         }
     };

     renderDeleteButton = (id) => (
         <IconButton aria-label='delete' onClick={() => this.deleteFromCart(id)}>
             <ClearIcon />
         </IconButton>
     );

     makeTable = (apiDataCart) => {
         const result = [];
         apiDataCart.forEach((cart) => {
             const finalPrice = this.getPrice(cart.item, cart.type);
             result.push(
                 <TableRow key={cart.id}>
                     <TableCell component='th' scope='row'>
                         {cart.item.name}
                     </TableCell>
                     <TableCell align='right'>{`${finalPrice.toFixed(2)}$`}</TableCell>
                     <TableCell size='small' align='right'>{this.renderDeleteButton(cart.id)}</TableCell>
                 </TableRow>
             );
         });
         return result;
     };

    renderCart = (apiDataCart, cartPrice) => {
        const result = [];
        if (apiDataCart) {
            result.push(
                <TableContainer component={Paper}>
                    <Table size='small' aria-label='a dense table'>
                        <TableBody>
                            {this.makeTable(apiDataCart)}
                            <TableRow key='cartPrice'>
                                <TableCell component='th' scope='row' style={{ fontWeight: 'bold' }}>Total:</TableCell>
                                <TableCell align='right' style={{ fontWeight: 'bold' }}>{`${cartPrice.toFixed(2)}$`}</TableCell>
                                <TableCell size='small' align='right' />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        }
        return result;
    };

    selector(selected: boolean) {
        this.setState({ selected });
    }

    render() {
        const { selected, apiDataCart, cartPrice } = this.state;
        const { type, goBackOneStep, goBack } = this.props;

        if (selected && apiDataCart.length > 0) {
            return (
                <Payment
                    title='Choose Payment Method'
                    amount={cartPrice}
                    cart={apiDataCart}
                    goBackOneStep={() => this.setState({ selected: false })}
                    goBack={goBack}
                />
            );
        }

        return (
            apiDataCart ? (
                <div>
                    <ServicesHeadTitle
                        client='Cabanas Nature Resort'
                        title={`${type} Checkout`}
                    />
                    <Grid
                        item
                        xs={6}
                        md={6}
                    >
                        <GoBackBtn goBackHandler={goBackOneStep} />
                    </Grid>
                    <Typography variant='h6' gutterBottom>
                        {apiDataCart.length !== 0 ? 'Details' : 'No itens in cart' }
                        {this.renderCart(apiDataCart, cartPrice)}
                    </Typography>
                    <Box p={3}>
                        <Grid
                            container
                            justify='center'
                            alignItems='center'
                            spacing={2}
                        >

                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <CheckoutButton clickHandler={() => { this.selector(true); }} />
                                <Button
                                    size='small'
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.clearUserCart(apiDataCart);
                                    }}
                                    style={{ paddingLeft: '3em' }}
                                >
                                    Clear cart
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            ) : <LoadingIndicator />

        );
    }
}
