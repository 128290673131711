import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography } from '@material-ui/core';

import styles from './styles';
import imgRest from '../../res/img/spa.jpg';

const useStyles = makeStyles(styles());
type Props = {
    title: string,
    description: string,
    image: string,
}

function getImage(image) {
    let result;
    try {
        result = `https://media.mtihotel.pt/Event/${image}`;
    } catch (e) {
        result = imgRest;
    }
    return result;
}

export default function CardSelect(props: Props) {
    const {
        title, description, image
    } = props;
    const classes = useStyles();
    return (
        <Card
            className={classes.root}
        >
            <CardMedia
                image={getImage(image)}
                className={classes.media}
            >
                <CardContent>
                    <Typography variant='h6' className={classes.h6}>
                        {title}
                    </Typography>
                    <Typography variant='body2'>
                        {description}
                    </Typography>

                </CardContent>

            </CardMedia>

        </Card>
    );
}
