import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import colors from '../../helpers/colors';
import styles from './styles';
import imgRest from '../../res/img/spa.jpg';

const useStyles = makeStyles(styles());

type Props = {
    name: String,
    storeName: String,
    discount: Number,
    price: Number,
    image: String,
}

function getImage(image) {
    if (image === '' || image === null) return imgRest;
    return `https://media.mtihotel.pt/vouchers/${image}`;
}

export default function VoucherCard(props:Props) {
    const {
        name,
        storeName,
        discount,
        price,
        image
    } = props;
    const classes = useStyles();

    return (
        <Card
            className={classes.root}
        >
            <CardMedia
                image={getImage(image)}
                className={classes.media}
            >

                <CardContent>
                    <CardActionArea>
                        <Grid container spacing={3}>
                            <Grid item xs />
                            <Grid item xs />
                            <Grid item xs>
                                <Chip label={`${discount}% discount`} clickable={false} style={{ backgroundColor: colors.primary }} />

                            </Grid>
                        </Grid>

                    </CardActionArea>
                    <Typography variant='h6' className={classes.h6}>
                        {name}
                    </Typography>
                    <Typography variant='body2'>
                        {storeName}
                    </Typography>
                    <Box pt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant='body2'>
                                    {price}
                                    {' €'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>

            </CardMedia>

        </Card>
    );
}
