import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import ItemCard from '../StoreItemCard';
import LoadingIndicator from '../LoadingIndicator';
import imgRest from '../../res/img/spa.jpg';
import styles from './styles';

const useStyles = makeStyles(styles());

type Props = {
    changeQty: Function,
    type: String,
    item: Array,
    products: Array
}

function renderProducts(products) {
    if (!products) {
        return (<LoadingIndicator />);
    }
    const result = [];
    products.forEach((item) => {
        result.push(
            <Grid
                item
                key={item.id}
                xs={6}
                md={6}
            >
                <ItemCard
                    item={item}
                    roomService
                />
            </Grid>
        );
    });
    return (<Grid container spacing={1}>{result}</Grid>);
}

function hasOptions(type, products) {
    if (type === 'Room Service') {
        return (
            <div>
                <Typography variant='h6' gutterBottom>
                    Products
                </Typography>
                {renderProducts(products)}
            </div>
        );
    }
    return null;
}

function getImage(image, type) {
    if (image === '' || image === null) return imgRest;
    return `https://media.mtihotel.pt/${type}/${image}`;
}

export default function index(props: Props) {
    const {
        changeQty, type, item, products
    } = props;
    const img = item.image.length > 0 ? item.image[0] : '';
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardMedia
                image={getImage(img, 'services')}
                className={classes.media}
            />
            <CardContent>
                <Typography variant='h6' gutterBottom>
                    Description
                </Typography>
                <Typography variant='body1' gutterBottom>
                    {item.description}
                </Typography>
                {hasOptions(type, products)}
                <Typography variant='h6' gutterBottom>
                    Price
                </Typography>
                <Typography variant='body1' gutterBottom>
                    {item.price}
                    {' '}
                    $
                </Typography>
                <TextField
                    id='qty'
                    label='Quantity'
                    type='number'
                    defaultValue='1'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => { changeQty(e.target.value); }}
                />
            </CardContent>
        </Card>
    );
}
