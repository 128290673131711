/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import { getRecentOffers } from '../../api/services';
import LoadingIndicator from '../../components/LoadingIndicator';
import OfferCard from '../../components/OfferCard';

export default class Index extends Component<> {
    constructor() {
        super();
        this.state = {
            apiData: false,
            room: '',
            clientId: '',
            userId: '',
        };
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const room = array[array.length - 3];
        const clientId = array[array.length - 2];
        const userId = array[array.length - 1];

        getRecentOffers(clientId, 3).then((response) => {
            this.setState({
                apiData: response,
                room,
                clientId,
                userId
            });
        });
    }

    renderOffers() {
        const {
            apiData, room, clientId, userId
        } = this.state;
        const result = [];
        let count = 0;
        apiData.forEach((element) => {
            result.push(
                <Grid
                    item
                    key={count += 1}
                    xs={12}
                    md={12}
                    onClick={() => {
                        window.location.href = `https://clientapp.mtihotel.pt/offers/${room}/${clientId}/${userId}`;
                    }}
                >
                    <OfferCard
                        title={element.title}
                        storeId={element.storeId}
                        description={element.description}
                        image={element.image}
                        discount={element.discount}
                    />
                </Grid>
            );
        });

        return result;
    }

    render() {
        const { apiData, room } = this.state;
        console.log(apiData);
        if (apiData.length > 0) {
            return (
                <Box p={3} pb={10}>
                    <ServicesHeadTitle client='Offers' title='Today' />
                    <Grid
                        container
                        justify='center'
                        alignItems='center'
                        spacing={2}
                    >
                        {room === 'null'
                            ? <Box pt={10}><Typography>Offers are not available at the moment</Typography></Box>
                            : this.renderOffers()}
                    </Grid>
                </Box>
            );
        }
        return (
            <Grid
                container
                justify='center'
                alignItems='center'
                spacing={2}
            >
                <Box pt={10}><Typography>Offers are not available at the moment</Typography></Box>
            </Grid>
        );
    }
}
