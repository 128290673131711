import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import Card from '../../components/Cards';
import StoreContent from '../StoreContent';
import GoBackBtn from '../../components/goBackButton';

type Props = {
    apiData: Array,
    title: string,
    goBack: Function,
    type: string
}

export default class Index extends Component<Props> {
    constructor() {
        super();

        this.state = {
            selected: false,
            shop: Array,
        };
    }

    selector(selected, shop) {
        this.setState({ selected, shop });
    }

    renderItems = (apiData: Array) => apiData.map((shop) => (
        <Grid
            item
            key={shop.id}
            xs={12}
            md={6}
            onClick={() => { this.selector(true, shop); }}
        >
            <Card
                title={shop.name}
                description={shop.description}
                image={shop.image.length > 0 ? shop.image[0] : ''}
                type='stores'
            />
        </Grid>
    ))

    render() {
        const { selected, shop } = this.state;
        const {
            apiData, title, goBack, type
        } = this.props;

        if (selected) {
            switch (type) {
            case 'Booking':
                return (
                    <StoreContent
                        shop={shop}
                        type='Booking'
                        goBackOneStep={() => this.setState({ selected: false })}
                        goBack={goBack}
                        roomService={false}
                    />
                );
            case 'Room Service':
                return (
                    <StoreContent
                        shop={shop}
                        type='Room Service'
                        goBackOneStep={() => this.setState({ selected: false })}
                        goBack={goBack}
                        roomService
                    />
                );
            case 'Shopping':
                return (
                    <StoreContent
                        shop={shop}
                        type='Shop'
                        goBackOneStep={() => this.setState({ selected: false })}
                        goBack={goBack}
                        roomService={false}
                    />
                );
            default:
                break;
            }
        }

        return (
            <div>
                <ServicesHeadTitle client='Cabanas Nature Resort' title={title} />
                <Box>
                    <Grid
                        container
                        justify='center'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <GoBackBtn goBackHandler={goBack} />
                        </Grid>

                        {this.renderItems(apiData)}
                    </Grid>
                </Box>

            </div>
        );
    }
}
