import colors from '../../helpers/colors';

const styles = () => ({
    root: {
        backgroundColor: colors.primary,
        color: colors.secondary,
    }
});

export default styles;
