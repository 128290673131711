import React, { Component } from 'react';
import 'fontsource-roboto';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import LoadingIndicator from '../../components/LoadingIndicator';
import GoBackBtn from '../../components/goBackButton';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import ActivityText from '../../components/ActivityText';
import { getActivityPerCategory, addActivtyToBookmark } from '../../api/services';

type Props = {
    item: Array,
    goBack: Function,
}
export default class index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            apiDataActivity: false,
            userId: 0,
        };
    }

    componentDidMount() {
        const { item } = this.props;
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        const clientId = array[array.length - 2];
        this.setState({ userId });
        const data = { clientId, category: item.id };
        getActivityPerCategory(data).then((response) => {
            this.setState({ apiDataActivity: response });
        });
    }

    activitiesCreate = (apiDataActivity) => {
        const result = [];
        if (apiDataActivity) {
            apiDataActivity.forEach((item) => {
                result.push(
                    <ActivityText
                        id={item.id}
                        date={item.date}
                        title={item.title}
                        description={item.description}
                        location={item.location}
                        saveBookmark={() => { this.addToBookmark(item); }}
                    />
                );
            });
        }
        if (apiDataActivity.length === 0) {
            result.push(
                <Typography style={{ fontWeight: 600 }}>
                    No activities available
                </Typography>
            );
        }
        return result;
    }

    addToBookmark = (item) => {
        const {
            userId
        } = this.state;
        const data = { userId, activity: item.id };
        addActivtyToBookmark(data);
    }

    render() {
        const {
            apiDataActivity
        } = this.state;
        const {
            goBack
        } = this.props;
        return (
            apiDataActivity ? (
                <div>
                    <ServicesHeadTitle client='' title='Activities' />
                    <Box>

                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <GoBackBtn goBackHandler={goBack} />
                        </Grid>
                        <Box p={1}>
                            {this.activitiesCreate(apiDataActivity)}
                        </Box>

                    </Box>

                </div>
            ) : <LoadingIndicator />
        );
    }
}
