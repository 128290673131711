import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import Button from '../../components/Button';
import StoreItemDetailCard from '../../components/StoreItemDetailCard';
import GoBackBtn from '../../components/goBackButton';

type Props = {
    shop: Array,
    detail: Array,
    type: String,
    addToCart: Function,
    goBack: Function,
}

export default class Index extends Component<Props> {
    constructor() {
        super();

        this.state = {
            qty: 1,
        };
        this.changeQty = this.changeQty.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        const { qty } = this.state;
        const { addToCart, goBack, detail } = this.props;

        if (qty <= 0) alert('Quantity invalid');
        else {
            for (let i = 0; i < qty; i += 1) {
                addToCart(detail);
            }
            goBack();
        }
    }

    changeQty(qty: Number) {
        this.setState({ qty });
    }

    render() {
        const {
            detail, shop, type, goBack
        } = this.props;

        return (
            <div>
                <ServicesHeadTitle client='Cabanas Nature Resort' title={`${shop.name} ${detail.name}`} />
                <Box>
                    <form onSubmit={this.handleSubmit}>
                        <Grid
                            container
                            justify='center'
                            alignItems='center'
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                md={12}
                            >
                                <GoBackBtn goBackHandler={goBack} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <StoreItemDetailCard
                                    changeQty={this.changeQty}
                                    type={type}
                                    item={detail}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Button text='Add to Cart' />
                            </Grid>
                        </Grid>
                    </form>

                </Box>
            </div>

        );
    }
}
