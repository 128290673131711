import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import styles from './styles';

const useStyles = makeStyles(styles());

type Props = {
    handleMenu: Function,
}

export default function MenuButton(props: Props) {
    const { handleMenu } = props;
    const classes = useStyles();

    return (
        <Button
            className={classes.root}
            endIcon={(
                <Badge>
                    <RestaurantIcon />
                </Badge>
            )}
            onClick={handleMenu}
        >
            Menu
        </Button>
    );
}
