import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import styles from './styles';

const useStyles = makeStyles(styles());

type Props = {
    qty: number,
    handleCheckout: Function
}

export default function CardSelect(props: Props) {
    const { qty, handleCheckout } = props;
    const classes = useStyles();

    return (
        <Button
            className={classes.root}
            endIcon={(
                <Badge badgeContent={qty} color='error'>
                    <ShoppingCartIcon />
                </Badge>
            )}
            onClick={handleCheckout}
        >
            My Cart
        </Button>
    );
}
