/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import Box from '@material-ui/core/Box';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import StoreTypes from '../../../pages/StoreTypes';
import OffersAndVouchers from '../../../pages/OffersAndVouchers';

type Props = {
    value: string
}

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Roboto', 'sans-serif'],
    }
});
export default class index extends PureComponent<Props> {
    constructor() {
        super();
        this.state = {
            access: '',
        };
    }

    /**
     * Page Selector based on prop value
     */
    pageSelector() {
        const { value } = this.props;
        let access = '';
        switch (value) {
        case 'page1':
            // this.setState({ access: 'booking' });
            access = 'booking';
            return <StoreTypes title='Booking' key={value} access={access} />;
        case 'page2':
            // this.setState({ access: 'roomService' });
            access = 'roomService';
            return <StoreTypes title='Room Service' key={value} access={access} />;
        case 'page3':
            // this.setState({ access: 'shopping' });
            access = 'shopping';
            return <StoreTypes title='Shopping' key={value} access={access} />;
        case 'page4':
            return <OffersAndVouchers title='Offers And Vouchers' />;
        default:
            return null;
        }
    }

    render() {
        return (
            <Box p={3} pb={10}>
                <ThemeProvider theme={theme}>
                    {this.pageSelector()}
                </ThemeProvider>
            </Box>
        );
    }
}
