import axios from 'axios';
import log from '../helpers/log';

/**
 * Request Wrapper with default success/error and logs
 */
const apiRequest = async (options: Object) => {
    const {
        REACT_APP_API_URL,
        REACT_APP_API_AUTH_KEY
    } = process.env;

    const request = axios.create({
        baseURL: REACT_APP_API_URL,
        method: 'POST',
        timeout: 1000000,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${REACT_APP_API_AUTH_KEY}`
        }
    });

    /**
     * onSuccess
     */
    const onSuccess = (response: Object) => {
        log.data('Request Successful!', response);
        return response.data;
    };

    /**
     * onError
     */
    const onError = (error: Object) => {
        log.data('Request Failed:', error, true);

        return Promise.reject(error.response || error.message);
    };

    return request(options).then(onSuccess).catch(onError);
};

export default apiRequest;
