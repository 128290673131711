import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import imgRest from '../../res/img/spa.jpg';

import styles from './styles';

const useStyles = makeStyles(styles());

type Props = {
    item: Array,
    addToCart: Function,
    handleDetail: Function,
}

function getImage(image) {
    let result;
    try {
        result = `https://media.mtihotel.pt/products/${image}`;
    } catch (e) {
        result = imgRest;
    }
    return result;
}

function getPrice(item) {
    let result;
    if (item.discount === 0 || item.discount === null) {
        result = (
            <Typography>
                { `${item.price} $` }
            </Typography>
        );
    } else {
        const price = item.price - item.price * (item.discount / 100);
        result = (
            <Typography>
                { `${price.toFixed(2)} $` }
            </Typography>
        );
    }
    return result;
}

export default function index(props: Props) {
    const {
        item, addToCart, handleDetail,
    } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea
                onClick={() => {
                    if (typeof handleDetail !== 'undefined') {
                        handleDetail(true, item);
                    }
                }}
            >
                <CardMedia
                    image={getImage(item.image)}
                    className={classes.media}
                />
                <CardContent>
                    <Typography>
                        { item.name }
                    </Typography>
                    {getPrice(item)}

                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button
                    size='small'
                    className={classes.btn}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (typeof addToCart !== 'undefined') {
                            addToCart(item);
                        }
                    }}
                >
                    Add to Cart
                </Button>
            </CardActions>
        </Card>

    );
}
