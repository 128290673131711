import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import Button from '../../components/Button';
import StoreItemDetailCard from '../../components/StoreItemDetailCard';
import GoBackBtn from '../../components/goBackButton';
import { serviceProducts } from '../../api/services';

type Props = {
    shop: Array,
    service: Object,
    type: String,
    goBackOneStep: Function,
    addCart: Function,
}

export default class Index extends Component<Props> {
    constructor() {
        super();

        this.state = {
            apiData: false,
            open: false,
            qty: 1,
        };
    }

    componentDidMount() {
        const { service } = this.props;
        serviceProducts(service.id).then((response) => {
            this.setState({ apiData: response });
        });
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const {
            shop,
            type,
            service,
            goBackOneStep,
            addCart,
        } = this.props;
        const { apiData, open, qty } = this.state;

        return (
            <div>
                <ServicesHeadTitle
                    client='Cabanas Nature Resort'
                    title={`${type} ${shop.name}`}
                    subtitle={service.name}
                />
                <Box>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <GoBackBtn goBackHandler={goBackOneStep} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <StoreItemDetailCard
                                changeQty={(e) => this.setState({ qty: e })}
                                type='Room Service'
                                item={service}
                                products={apiData}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <Button onClick={service.purchase_type_id === 3 && type === 'Shop' ? this.handleClickOpen : () => { addCart(service, 'service', qty); }} text='Add to Cart' />
                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>This service can also be booked</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Do you want to pick it up in the store?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button text='No' onClick={goBackOneStep} color='primary' />
                        <Button text='Yes' onClick={() => { addCart(service, 'service', qty); }} color='primary' autoFocus />
                    </DialogActions>
                </Dialog>
            </div>

        );
    }
}
