/**
 * TODO use colors.background etc
 */

const styles = () => ({
    root: {
        color: '#92b096',
        '&$selected': {
            color: 'gray',
        },
    },
    selected: {},
    label: {
        color: '#92b096',
        boxSizing: 'border-box',
        height: '35px',
        lineHeight: '15px',
        fontSize: '10px',
        fontWeight: '400',
    },
});

export const toolbarStyle = {
    background: '#ffffff',
    width: '100%',
    height: '65px',
    position: 'fixed',
    bottom: 0,
    overflow: 'visible',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    boxShadow: '0.3em 0.3em 1em rgba(0,0,0,0.4)',

};

export default styles;
