import colors from '../../helpers/colors';

const styles = {
    color: colors.secondary,
    borderRadius: '50%',
    backgroundColor: colors.primary,
    padding: '8px'

};

export default styles;
