import React, { Component } from 'react';
import NotificationItem from '../../components/NotificationItem';

// eslint-disable-next-line react/prefer-stateless-function
export default class Index extends Component {
    render() {
        return (
            <NotificationItem />
        );
    }
}
