import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

type Props = {
    deleteBookmark: Function,
    title: string,
    subtitle: string,
    id: Number,
}

export default function Index(props: Props) {
    const {
        title, subtitle, deleteBookmark, id
    } = props;
    const styles = {
        textTransform: 'uppercase',
    };

    return (
        <Box component='span' display='block' p={1}>
            <Button
                size='small'
                onClick={() => {
                    deleteBookmark(id);
                }}
            >
                Delete from Bookmark
            </Button>
            <Typography color='textSecondary' variant='subtitle1' style={styles}>
                {title}
            </Typography>
            <Typography variant='h5' gutterBottom>
                {subtitle}
            </Typography>
        </Box>
    );
}
