/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { getStoreWorkingHours } from '../../api/services';
import TimeSlotCell from '../TimeSlotCell';
import styles from '../TimeSlotCell/styles';

const useStyles = makeStyles(styles());

type Props = {
    storeId: Number,
    numberUsers: Number,
    date: String
}

function renderCells(workingHours, storeId, numberUsers, date) {
    const result = [];
    const classes = useStyles();

    workingHours.forEach((element) => {
        let currentTime = element.start_hour.split(':');
        let tempTime = element.start_hour.split(':');

        let currentDate = new Date(date);
        let tempDate = new Date(date);

        const endTime = element.end_hour.split(':');

        while (true) {
            let nextHour = parseInt(tempTime[0], 10) + 1;
            if (nextHour > 23) {
                nextHour = 0;
                tempDate.setDate(tempDate.getDate() + 1);
            }

            nextHour = nextHour.toString();
            nextHour = nextHour.length < 2 ? `0${nextHour}` : nextHour;

            tempTime[0] = nextHour;

            let startHour = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()} ${currentTime.join(':')}`;
            let endHour = `${tempDate.getFullYear()}-${tempDate.getMonth() + 1}-${tempDate.getDate()} ${tempTime.join(':')}`;

            result.push(
                <TimeSlotCell
                    storeId={storeId}
                    startHour={startHour}
                    endHour={endHour}
                    numberPeople={numberUsers}
                    key={currentTime.join(':')}
                />
            );

            if (parseInt(tempTime[0], 10) === parseInt(endTime[0], 10)) {
                break;
            }
            currentTime[0] = tempTime[0];
            currentDate = tempDate;
        }
        result.push(
            <ListItem divider classes={{ root: classes.disabled }} />
        );
    });
    result.pop();
    return result;
}

export default function TimeSlotCalendar(props:Props) {
    const [workingHours, setWorkingHours] = useState([]);
    const { storeId, numberUsers, date } = props;

    useEffect(() => {
        getStoreWorkingHours(storeId).then((val) => {
            setWorkingHours(val);
        });
    }, []);
    return (
        <div>
            <Typography variant='h6'>
                Availability Calendar
            </Typography>
            <List dense>
                { renderCells(workingHours, storeId, numberUsers, date) }
            </List>
        </div>
    );
}
