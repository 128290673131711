/* eslint-disable no-console */
/**
 * An abstractin for logging data.
 */
const log = {
    css: {
        data: 'color: blue; background-color: silver',
        info: 'color: yellow; background-color: black',
        error: 'color: red; background-color: black',
        default: 'color: black; background-color: transparent'
    },

    /**
     * log info text and saves to file
     */
    info: (text: string) => {
        console.info(`%c [INFO] %c ${text}`, log.css.info, log.css.default);
    },

    /**
     * log error text and saves to file
     */
    error: (text: string, error: any = '') => {
        console.error(`%c [ERROR] %c ${text}`, log.css.error, log.css.default, error);
    },

    /**
     * log data
     */
    data: (text: string, msg: any, error: boolean = false) => {
        console.log(
            `%c [${text}] %c`,
            error ? log.css.error : log.css.data,
            log.css.default,
            msg
        );
    }
};

export default log;
