/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import LoadingIndicator from '../../components/LoadingIndicator';
import { getVoucherDetails, getCart, addToCart } from '../../api/services';
import VoucherDetailCard from '../../components/VoucherDetailCard';

type Props = {
    voucherId: Number,
    handleShowGoBack: Function,
    handleCartLength: Function,
}

export default class Index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            voucherDetails: [],
            userId: 0,
        };
    }

    componentDidMount() {
        const { voucherId, handleShowGoBack } = this.props;
        handleShowGoBack();
        getVoucherDetails(voucherId).then((response) => {
            this.setState({ voucherDetails: response });
        });
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        this.setState({ userId });
    }

    addToCart(item: Array) {
        const { userId } = this.state;
        const { handleCartLength } = this.props;
        const data = {
            userId, serviceId: null, productId: null, voucherId: item
        };
        addToCart(data).then(() => {
            getCart(userId).then((response) => {
                handleCartLength(response.length);
            });
        });
    }

    render() {
        const { voucherDetails } = this.state;
        const { voucherId } = this.props;
        if (Object.keys(voucherDetails).length > 0) {
            return (
                <VoucherDetailCard
                    data={voucherDetails}
                    addToCart={() => { this.addToCart(voucherId); }}
                />
            );
        }
        return (<LoadingIndicator />);
    }
}
