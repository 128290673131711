/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import Card from '../../components/Cards';
import StoreItemCard from '../../components/StoreItemCard';
import Booking from '../Booking';
import {
    storeBookingServices as services, storeItems, getCart, addToCart
} from '../../api/services';
import StoreCheckout from '../StoreCheckout';
import StoreMenu from '../../components/StoreMenu';
import LoadingIndicator from '../../components/LoadingIndicator';
import CartButton from '../../components/CartButton';
import MenuButton from '../../components/MenuButton';
import GoBackBtn from '../../components/goBackButton';
import ServiceDetail from '../ServiceDetail';
import colors from '../../helpers/colors';

type Props = {
    shop: Array,
    roomService: Boolean,
    type: string,
    goBack: Function,
    goBackOneStep: Function,
}

export default class Index extends Component<Props> {
    constructor() {
        super();

        this.state = {
            selected: false,
            cartLength: 0,
            checkout: false,
            apiData: false,
            service: Array,
            userId: 0,
            tab: 0,
            menu: false,
        };
        this.addToCart = this.addToCart.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
    }

    componentDidMount() {
        const { shop, type } = this.props;
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        this.setState({ userId });
        if (type === 'Room Service') {
            storeItems(shop.id, 'room-service').then((response) => {
                this.setState({ apiData: response });
            });
        } else if (type === 'Shop') {
            storeItems(shop.id, 'shopping').then((response) => {
                this.setState({ apiData: response });
            });
        } else if (type === 'Booking') {
            services(shop.id).then((response) => {
                this.setState({ apiData: response });
            });
        }
        getCart(userId).then((response) => {
            this.setState({ cartLength: response.length });
        });
    }

    handleCheckout() {
        this.setState({ checkout: true });
    }

    handleMenu() {
        this.setState({ menu: true });
    }

    selector(selected: boolean, service: Array) {
        this.setState({ selected, service });
    }

    addToCart(item: Array, type: String, qty: Number = 1) {
        this.setState({ selected: false });
        if (qty < 1) qty = 1;

        const { userId } = this.state;
        let data;
        if (type === 'service') data = { userId, serviceId: item.id, productId: null };
        else if (type === 'product') data = { userId, serviceId: null, productId: item.id };
        for (let index = 0; index < qty; index += 1) {
            addToCart(data).then(() => {
                getCart(userId).then((response) => {
                    this.setState({ cartLength: response.length });
                });
            });
        }
    }

    tabBar(tab) {
        const handleChange = (event, val) => {
            this.setState({ tab: val });
        };
        return (
            <Grid
                item
                xs={12}
                md={12}
            >
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    TabIndicatorProps={
                        {
                            style: {
                                backgroundColor: colors.primary
                            }
                        }
                    }
                >
                    <Tab label='services' />
                    <Tab label='products' />
                </Tabs>
            </Grid>
        );
    }

    goBackBtn() {
        const {
            goBackOneStep
        } = this.props;
        return (
            <Grid
                item
                xs={12}
                md={12}
            >
                <GoBackBtn goBackHandler={goBackOneStep} />
            </Grid>
        );
    }

    cart(cartLength) {
        const { goBackOneStep } = this.props;
        return (
            <>
                <Grid
                    item
                    xs={6}
                    md={6}
                >
                    <GoBackBtn goBackHandler={goBackOneStep} />
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={6}
                >
                    <Box display='flex' flexDirection='row-reverse'>
                        <CartButton
                            qty={cartLength}
                            handleCheckout={this.handleCheckout}
                        />
                    </Box>
                    <Box display='flex' flexDirection='row-reverse'>
                        <MenuButton
                            handleMenu={this.handleMenu}
                        />
                    </Box>
                </Grid>
            </>
        );
    }

    renderStoreItems(apiData, type) {
        const result = [];
        if (type === 'services') {
            apiData.Services.forEach((service) => {
                result.push(
                    <Grid
                        item
                        key={service.id}
                        mb={1}
                        xs={12}
                        md={6}
                        onClick={() => { this.selector(true, service); }}
                    >
                        <Card
                            title={service.name}
                            description={service.description}
                            image={service.image.length > 0 ? service.image[0] : ''}
                            roomService
                            price={service.price}
                            type='services'
                        />
                    </Grid>
                );
            });
        } else if (type === 'products') {
            apiData.Products.forEach((item) => {
                result.push(
                    <Grid
                        item
                        key={item.id}
                        xs={6}
                        md={6}
                    >
                        <StoreItemCard
                            item={item}
                            image={item.image.length > 0 ? item.image[0] : ''}
                            addToCart={() => { this.addToCart(item, 'product'); }}
                            handleDetail={this.handleDetail}
                        />
                    </Grid>
                );
            });
            return (
                <Grid container spacing={1}>{result}</Grid>
            );
        }

        return result;
    }

    renderStoreContent(tab, apiData) {
        if (tab === 0) {
            return this.renderStoreItems(apiData, 'services');
        }
        return this.renderStoreItems(apiData, 'products');
    }

    renderBookingServices(apiData: Array, roomService: Boolean) {
        const result = [];
        apiData.forEach((service) => {
            result.push(
                <Grid
                    item
                    key={service.id}
                    xs={12}
                    md={6}
                    onClick={() => { this.selector(true, service); }}
                >
                    <Card
                        title={service.name}
                        description={service.description}
                        id={service.id}
                        image={service.image.length > 0 ? service.image[0] : ''}
                        roomService={roomService}
                        price={service.price}
                        type='services'
                    />
                </Grid>
            );
        });
        return result;
    }

    render() {
        const {
            selected, service, apiData, cartLength, checkout, tab, menu
        } = this.state;
        const {
            shop, goBack, roomService, type
        } = this.props;

        if (selected && type === 'Booking') {
            return (
                <Booking
                    shop={shop}
                    service={service}
                    goBack={goBack}
                    goBackOneStep={() => this.setState({ selected: false })}
                />
            );
        }

        if (selected && (type === 'Room Service' || type === 'Shop')) {
            return (
                <ServiceDetail
                    shop={shop}
                    service={service}
                    type={type}
                    goBackOneStep={() => this.setState({ selected: false })}
                    addCart={this.addToCart}
                />
            );
        }

        if (checkout) {
            return (
                <StoreCheckout
                    type={type}
                    goBackOneStep={() => this.setState({ checkout: false })}
                    goBack={goBack}
                />
            );
        }

        if (menu) {
            return (
                <StoreMenu
                    goBackOneStep={() => this.setState({ menu: false })}
                    shop={shop}
                    type={type}
                />
            );
        }

        return (
            apiData ? (
                <div>
                    <ServicesHeadTitle client='Cabanas Nature Resort' title={`${type} ${shop.name}`} />
                    <Box>
                        <Grid
                            container
                            justify='center'
                            alignItems='center'
                            spacing={2}
                        >

                            {type === 'Booking'
                                ? this.goBackBtn()
                                : this.cart(cartLength)}
                            {type === 'Booking'
                                ? ''
                                : this.tabBar(tab)}
                            {type === 'Booking'
                                ? this.renderBookingServices(apiData, roomService)
                                : this.renderStoreContent(tab, apiData)}
                        </Grid>
                    </Box>
                </div>
            ) : <LoadingIndicator />
        );
    }
}
