/* eslint-disable arrow-body-style */
import i18n from 'i18n-js';
import pt from './translations/pt.json';

i18n.translations = { pt };
i18n.fallbacks = true;

i18n.defaultLocale = 'pt-PT';
i18n.locale = 'pt-PT';
i18n.currentLocale();

/**
 * Missing Translation
 */
i18n.missingTranslation = (message) => {
    // console.log('Missing Translation', message);
    return message;
};

export default i18n;
