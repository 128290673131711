/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GoBackBtn from '../../components/goBackButton';
import IconButton from '../../components/IconButton';
import LoadingIndicator from '../../components/LoadingIndicator';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';

import {
    paymentPaypal,
    createPaymentCreditCard,
    creditCardInfo,
    shoppingCartOrders,
    clearCart,
    purchaseVouchers
} from '../../api/services';

type Props = {
    title: string,
    amount: Number,
    cart: Array,
    goBackOneStep: Function,
    goBack: Function,
}

export default function Index(props: Props) {
    const {
        title, goBackOneStep, goBack, amount, cart
    } = props;

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(!open);
    };

    const handleLoading = () => {
        setLoading(!loading);
    };

    const getCurrentDate = () => {
        const today = new Date();
        const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
        return date;
    };

    const getQuantity = (id) => {
        let count = 0;
        cart.forEach((c) => {
            if (c.item.id === id && typeof c.item.is_room_service === 'undefined') {
                count += 1;
            }
        });
        return count;
    };

    const structureOrders = () => {
        const array = window.location.href.split('/');
        const userId = parseInt(array[array.length - 1], 10);
        const clientId = parseInt(array[array.length - 2], 10);
        const roomNumber = array[array.length - 3];
        const orders = [];
        const orderByStore = [];
        cart.forEach((c) => {
            if (!orderByStore.includes(c.item.store_id)) {
                orderByStore.push(c.item.store_id);
            }
        });
        let date = new Date(getCurrentDate());
        const refe = date.getTime() + userId;
        const reference = refe.toString(10);
        date = getCurrentDate();
        orderByStore.forEach((id) => {
            const products = [];
            const services = [];
            cart.forEach((c) => {
                if (id === c.item.store_id) {
                    if (c.type === 'service') {
                        services.push(c.item.id);
                    } else if (c.type === 'product' && !products.some((prod) => prod.product_id === c.item.id)) {
                        products.push({ product_id: c.item.id, quantity: getQuantity(c.item.id) });
                    }
                }
            });
            orders.push({
                Order: {
                    store_id: id,
                    service_id: null,
                    type_id: 2,
                    user_id: userId,
                    state_id: 7,
                    reference,
                    client_id: clientId,
                    room: roomNumber,
                    products,
                }
            });
            services.forEach((s) => {
                orders.push({
                    Order: {
                        store_id: id,
                        service_id: s,
                        user_id: userId,
                        type_id: 2,
                        state_id: 7,
                        reference,
                        client_id: clientId,
                        room: roomNumber,
                        products: null,
                    }
                });
            });
        });
        return orders;
    };

    const structureVouchers = () => {
        const array = window.location.href.split('/');
        const userId = parseInt(array[array.length - 1], 10);
        const roomNumber = array[array.length - 3];

        const result = [];
        cart.forEach((c) => {
            if (c.type === 'voucher') {
                result.push(
                    {
                        voucher_id: c.item.id,
                        quantity: 1,
                        room: roomNumber,
                        user_id: userId,
                        offer: 0,
                        state: 1,
                    }
                );
            }
        });
        return result;
    };

    const shoppingReception = () => {
        const orders = structureOrders();
        shoppingCartOrders(orders);
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        clearCart(userId);
        window.location.reload();
    };

    const shoppingPaypal = () => {
        handleLoading();
        const array = window.location.href.split('/');
        const clientId = parseInt(array[array.length - 2], 10);
        const userId = array[array.length - 1];
        const orders = structureOrders();
        const vouchers = structureVouchers();
        purchaseVouchers(vouchers).then(() => {
            shoppingCartOrders(orders).then((reference) => {
                const data = {
                    client_id: clientId, amount, currency: 'EUR', url: `?reference=${reference}&user=${userId}`
                };
                paymentPaypal(data).then((response) => {
                    if (response.status === 'CREATED') {
                        window.parent.location.replace(response.links[1].href);
                    }
                });
            });
        });
    };

    const shoppingCreditCard = () => {
        handleLoading();
        const array = window.location.href.split('/');
        const clientId = parseInt(array[array.length - 2], 10);
        const orders = structureOrders();
        shoppingCartOrders(orders).then((reference) => {
            creditCardInfo(clientId).then((response) => {
                const reduniqAmount = amount * 100;
                const returnUrl = `${response.reduniq_return_url}?reference=${reference}`;
                const data = {
                    client_id: clientId, username: response.reduniq_username, password: response.reduniq_password, amount: reduniqAmount, returnURL: returnUrl, buyerEmail: 'email@email.com',
                };
                createPaymentCreditCard(data).then((responsePayment) => {
                    const n = responsePayment.indexOf('https://pagamentos.reduniq');
                    const str = responsePayment.substring(n);
                    window.parent.location.replace(str.split('<')[0]);
                });
            });
        });
    };

    return (
        <div>
            <ServicesHeadTitle client='Cabanas Nature Resort' title={title} />
            <Box>
                <Grid
                    container
                    justify='center'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={12}
                    >
                        <GoBackBtn goBackHandler={goBackOneStep} />
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                        spacing={0}
                    >
                        <Grid item xs md>
                            <IconButton type='paypal' paymentMethod={shoppingPaypal} />
                        </Grid>
                        <Grid item xs md>
                            <IconButton type='reception' paymentMethod={handleClickOpen} />
                        </Grid>
                        <Grid item xs md>
                            <IconButton type='creditCard' paymentMethod={shoppingCreditCard} />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={12}
                        onClick={handleClickOpen}
                    >
                        <div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby='alert-dialog-title'
                                aria-describedby='alert-dialog-description'
                            >
                                <DialogTitle id='alert-dialog-title'>Confirm order?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id='alert-dialog-description'>
                                        Finish payment on hotel reception
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color='primary'>
                                        Cancel
                                    </Button>
                                    <Button onClick={shoppingReception} color='primary' autoFocus>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={loading}
                                aria-labelledby='alert-dialog-title'
                                aria-describedby='alert-dialog-description'
                                fullScreen='true'
                            >
                                <LoadingIndicator />
                            </Dialog>
                        </div>
                    </Grid>
                </Grid>
            </Box>

        </div>
    );
}
