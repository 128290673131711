/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import ServicesHeadTitle from '../ServicesHeadTitle';
import GoBackBtn from '../goBackButton';
import LoadingIndicator from '../LoadingIndicator';
import { storeDigitalMenu, products, services } from '../../api/services';

type Props = {
    type: String,
    goBackOneStep: Function,
    shop: Array,
};

export default class Index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            apiData: false,
            apiProduct: false,
            apiService: false,
        };
    }

    componentDidMount() {
        const { shop } = this.props;
        const productsIds = [];
        const servicesIds = [];
        storeDigitalMenu(shop.id).then((response) => {
            this.setState({ apiData: response });
            response.forEach((s) => {
                if (s.type === 3) {
                    productsIds.push(s.product_id);
                }
                if (s.type === 5) {
                    servicesIds.push(s.service_id);
                }
            });
            products(productsIds).then((result) => {
                this.setState({ apiProduct: result });
            });
            services(servicesIds).then((result) => {
                this.setState({ apiService: result });
            });
        });
    }

    getProduct = (s, dataProduct) => {
        let result;
        dataProduct.forEach((p) => {
            if (p.id === s.product_id) {
                result = p;
            }
        });
        return result;
    }

    getService = (s, dataService) => {
        let result;
        dataService.forEach((p) => {
            if (p.id === s.service_id) {
                result = p;
            }
        });
        return result;
    }

    partMenu = (data, dataProduct, dataService, initId, endId) => {
        const result = [];
        if (dataProduct.length > 0) {
            data.forEach((s) => {
                if (s.id > initId && s.id <= endId) {
                    switch (s.type) {
                    case 1:
                        result.push(
                            <Grid item xs={12} key={s.id}>
                                <Typography
                                    variant='h5'
                                    gutterBottom
                                    align='center'
                                    style={{ fontWeight: 'bold', marginBottom: '-0.25em' }}
                                >
                                    {s.value}
                                </Typography>
                            </Grid>
                        );
                        break;
                    case 2:
                        result.push(
                            <Grid item xs={12} key={s.id}>
                                <Typography
                                    variant='subtitle1'
                                    component='h6'
                                    align='center'
                                    style={{ fontStyle: 'italic', fontSize: '.8rem' }}
                                >
                                    {s.value}
                                </Typography>
                            </Grid>
                        );
                        break;
                    case 3:
                        const product = this.getProduct(s, dataProduct);
                        result.push(
                            <Grid
                                item
                                xs={12}
                                key={s.id}
                                style={{ marginTop: '.5em' }}
                            >
                                <Grid container justify='space-between'>
                                    <Typography
                                        style={{ fontWeight: 900, display: 'inline-block', fontFamily: 'Georgia' }}
                                        align='left'
                                    >
                                        {product.name}

                                    </Typography>
                                    <Typography
                                        style={{ fontWeight: 900 }}
                                        align='right'
                                    >
                                        {`${product.price}$`}
                                    </Typography>
                                </Grid>

                                <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                    style={{ fontSize: '.8rem' }}
                                >
                                    <Box
                                        fontStyle='italic'
                                    >
                                        {product.description}
                                    </Box>
                                </Typography>

                            </Grid>
                        );
                        break;
                    case 5:
                        const service = this.getService(s, dataService);
                        result.push(
                            <Grid
                                item
                                xs={12}
                                key={s.id}
                                style={{ marginTop: '.5em' }}
                            >
                                <Grid container justify='space-between'>
                                    <Typography
                                        style={{ fontWeight: 900, display: 'inline-block', fontFamily: 'Georgia ' }}
                                        align='left'
                                    >
                                        {service.name}

                                    </Typography>
                                    <Typography
                                        style={{ fontWeight: 900 }}
                                        align='right'
                                    >
                                        {`${service.price}$`}
                                    </Typography>
                                </Grid>

                                <Typography
                                    variant='subtitle2'
                                    gutterBottom
                                >
                                    <Box
                                        fontStyle='italic'
                                    >
                                        {service.description}
                                    </Box>
                                </Typography>

                            </Grid>
                        );
                        break;
                    default:
                    }
                }
            });
        }
        return result;
    }

    allMenu = (data, dataProduct, dataService) => {
        const result = [];
        if (dataProduct.length > 0) {
            data.forEach((s) => {
                switch (s.type) {
                case 1:
                    result.push(
                        <Grid item xs={12} key={s.id}>
                            <Typography
                                variant='h5'
                                gutterBottom
                                align='center'
                                style={{ fontWeight: 'bold', marginBottom: '-0.25em' }}
                            >
                                {s.value}
                            </Typography>
                        </Grid>
                    );
                    break;
                case 2:
                    result.push(
                        <Grid item xs={12} key={s.id}>
                            <Typography
                                variant='subtitle1'
                                component='h6'
                                align='center'
                                style={{ fontStyle: 'italic', fontSize: '.8rem' }}
                            >
                                {s.value}
                            </Typography>
                        </Grid>
                    );
                    break;
                case 3:
                    const product = this.getProduct(s, dataProduct);
                    result.push(
                        <Grid
                            item
                            xs={12}
                            key={s.id}
                            style={{ marginTop: '.5em' }}
                        >
                            <Grid container justify='space-between'>
                                <Typography
                                    style={{ fontWeight: 900, display: 'inline-block', fontFamily: 'Georgia ' }}
                                    align='left'
                                >
                                    {product.name}

                                </Typography>
                                <Typography
                                    style={{ fontWeight: 900 }}
                                    align='right'
                                >
                                    {`${product.price}$`}
                                </Typography>
                            </Grid>

                            <Typography
                                variant='subtitle2'
                                gutterBottom
                            >
                                <Box
                                    fontStyle='italic'
                                >
                                    {product.description}
                                </Box>
                            </Typography>

                        </Grid>
                    );
                    break;
                case 4:
                    result.push(
                        <Grid key={s.id} item xs={12}>
                            <hr style={{ width: 310, borderColor: 'gray' }} />
                        </Grid>
                    );
                    break;
                case 5:
                    const service = this.getService(s, dataService);
                    result.push(
                        <Grid
                            item
                            xs={12}
                            key={s.id}
                            style={{ marginTop: '.5em' }}
                        >
                            <Grid container justify='space-between'>
                                <Typography
                                    style={{ fontWeight: 900, display: 'inline-block', fontFamily: 'Georgia ' }}
                                    align='left'
                                >
                                    {service.name}

                                </Typography>
                                <Typography
                                    style={{ fontWeight: 900 }}
                                    align='right'
                                >
                                    {`${service.price}$`}
                                </Typography>
                            </Grid>

                            <Typography
                                variant='subtitle2'
                                gutterBottom
                            >
                                <Box
                                    fontStyle='italic'
                                >
                                    {service.description}
                                </Box>
                            </Typography>

                        </Grid>
                    );
                    break;
                default:
                }
            });
        }

        if (result.length === 0) {
            return (
                <Typography>
                    No products available
                </Typography>
            );
        }
        return result;
    }

    isSameImage = (data) => {
        let result = true;
        let imageName = '';
        data.forEach((s) => {
            if (s.type === 1 && imageName === '' && s.image !== null) {
                imageName = s.image;
            } else if (s.type === 1 && s.image === null) result = false;
            else if (s.type === 1 && s.image !== imageName) result = false;
        });
        return result;
    }

    getImageName = (data) => {
        let result = '';
        data.forEach((s) => {
            if (s.type === 1) {
                result = s.image;
                return result;
            }
        });
        return result;
    }

    getImageNamePartly = (data, initId, endId) => {
        let result = '';
        data.forEach((s) => {
            if (s.type === 1 && s.id > initId && s.id < endId) {
                result = s.image;
                return result;
            }
        });
        return result;
    }

    getImage = (image) => {
        const { shop } = this.props;
        let result;
        try {
            result = `https://media.mtihotel.pt/typeRnbMenu/${shop.id + image}`;
        } catch (e) {
            result = '';
        }
        return result;
    }

    menuCreate = (data, dataProduct, dataService) => {
        const result = [];
        let initId = 0;
        const sameImage = this.isSameImage(data);
        if (sameImage) {
            const imageName = this.getImageName(data);
            result.push(
                <Grid
                    item
                    xs={12}
                >
                    <Card>
                        <CardMedia
                            image={this.getImage(imageName)}
                            style={{
                                boxShadow: 'inset 0 -3em 3em rgba(0,0,0,0.4)',
                            }}
                        >
                            <CardContent>
                                {this.allMenu(data, dataProduct, dataService)}
                            </CardContent>
                        </CardMedia>

                    </Card>
                </Grid>
            );
        } else {
            let arrayPos = 0;
            data.forEach((s) => {
                arrayPos += 1;
                if (s.type === 4 || arrayPos === data.length) {
                    const imageName = this.getImageNamePartly(data, initId, s.id);
                    if (imageName === null) {
                        result.push(
                            <Grid
                                item
                                xs={12}
                                key={s.id}
                            >
                                <Card>
                                    <CardMedia
                                        image={this.getImage(imageName)}
                                        style={{
                                            boxShadow: 'inset 0 -3em 3em rgba(0,0,0,0.4)',
                                            background: 'rgba(123, 239, 178, 0.3)'
                                        }}
                                    >
                                        <CardContent>
                                            {this.partMenu(
                                                data,
                                                dataProduct,
                                                dataService,
                                                initId,
                                                s.id
                                            )}
                                        </CardContent>
                                    </CardMedia>

                                </Card>
                            </Grid>
                        );
                    } else {
                        result.push(
                            <Grid
                                item
                                xs={12}
                                key={s.id}
                            >
                                <Card>
                                    <CardMedia
                                        image={this.getImage(imageName)}
                                        style={{
                                            boxShadow: 'inset 0 -3em 3em rgba(0,0,0,0.4)',
                                        }}
                                    >
                                        <CardContent>
                                            {this.partMenu(
                                                data,
                                                dataProduct,
                                                dataService,
                                                initId,
                                                s.id
                                            )}
                                        </CardContent>
                                    </CardMedia>

                                </Card>
                            </Grid>
                        );
                    }

                    result.push(
                        <Grid item xs={12}>
                            <hr style={{ width: 310, borderColor: 'gray' }} />
                        </Grid>
                    );
                    initId = s.id;
                }
            });
        }
        if (result.length === 0) {
            return (
                <Typography>
                    No products available
                </Typography>
            );
        }
        return result;
    }

    render() {
        const {
            type, shop, goBackOneStep
        } = this.props;
        const { apiData, apiProduct, apiService } = this.state;
        return (
            apiData && apiProduct && apiService ? (
                <div>
                    <ServicesHeadTitle
                        client='Cabanas Nature Resort'
                        title={`${type} ${shop.name}`}
                    />
                    <GoBackBtn goBackHandler={goBackOneStep} />

                    <hr />
                    { this.menuCreate(apiData, apiProduct, apiService) }
                </div>
            ) : <LoadingIndicator />

        );
    }
}
