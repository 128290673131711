import colors from '../../helpers/colors';

const styles = () => ({
    root: {
        width: '100%',
    },
    media: {
        height: 80,
        width: '100%',
    },
    content: {
        height: 40,
    },
    btn: {
        color: colors.primary,
    }
});

export default styles;
