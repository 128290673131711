/* eslint-disable no-multiple-empty-lines */
import apiRequest from './apiRequest';

/**
 * Services request
 */
export const stores = (clientId, access) => {
    const request = {
        url: '/v1/app/stores',
        data: {
            client_id: clientId,
            access
        }
    };
    return apiRequest(request);
};

export const storeBookingServices = (storeId) => {
    const request = {
        url: '/v1/app/booking-services',
        data: {
            store_id: storeId
        }
    };

    return apiRequest(request);
};

export const storeServices = (storeId) => {
    const request = {
        url: '/v1/app/services',
        data: {
            store_id: storeId
        }
    };

    return apiRequest(request);
};

export const storeDigitalMenu = (storeId) => {
    const request = {
        url: '/v1/app/store-digital-menu',
        data: {
            store_id: storeId
        }
    };

    return apiRequest(request);
};

export const products = (productId) => {
    const request = {
        url: '/v1/app/products',
        data: {
            product_id: productId
        }
    };

    return apiRequest(request);
};

export const services = (serviceId) => {
    const request = {
        url: '/v1/app/multiple-services',
        data: {
            service_id: serviceId
        }
    };

    return apiRequest(request);
};

export const reservation = (data) => {
    const request = {
        url: '/v1/app/reservation',
        data: {
            Order: {
                store_id: data.shop,
                number_people: data.numberUsers,
                service_id: data.service,
                user_id: data.user,
                type_id: 1,
                state_id: 1,
                client_id: data.client,
                room: data.room,
                request_date: data.dateTime,
            }
        }
    };

    return apiRequest(request);
};

/**
 * Gets a stores Room Services
 * @param {*} storeId An integer with the id of the store
 * @returns array with services of type room service
 */
export const storeRoomServices = (storeId) => {
    const request = {
        url: '/v1/app/room-service',
        data: {
            store_id: storeId,
        }
    };

    return apiRequest(request);
};

/**
 * Gets a stores items
 * @param {*} storeId An integer with the id of the store
 * @param {*} type An string that can either be 'room-service' or 'shopping'
 * @returns array with products and services
 */
export const storeItems = (storeId, type) => {
    const request = {
        url: '/v1/app/store-items',
        data: {
            store_id: storeId,
            type
        }
    };

    return apiRequest(request);
};

/**
 * Gets all the products of a specific store
 * @param {*} storeId An integer that represents the id of the store
 * @returns An array with all the products of the store
 */
export const storeProducts = (storeId) => {
    const request = {
        url: '/v1/app/store-products',
        data: {
            store_id: storeId,
        }
    };

    return apiRequest(request);
};

/**
 * Gets all the notifications of a specific user
 * @returns An array with all the notifications of the user
 */
export const notifications = (data) => {
    const request = {
        url: '/v1/app/notifications',
        data: {
            user_id: data.userId,
        }
    };

    return apiRequest(request);
};

/**
 * Gets all the allowed notifications of a specific user
 * @returns An array with all the notifications of the user
 */
export const allowedNotifications = (data) => {
    const request = {
        url: '/v1/app/get-allowed-notifications',
        data: {
            user_id: data.userId,
        }
    };

    return apiRequest(request);
};

/**
 * Sets the passed notification has read
 * @param {*} id An integer that represents the id of the notification
 * @returns An array with all the notifications of the user
 */
export const readNotifications = (id) => {
    const request = {
        url: '/v1/app/read-notifications',
        data: {
            notification_id: id,
        }
    };

    return apiRequest(request);
};

/**
 * Gets all the products that belong to a service
 * @param {*} orderId integer that represents the id of the service
 * @returns An array with all the products of the service
 */
export const orderDetails = (orderId) => {
    const request = {
        url: '/v1/app/order-details',
        data: {
            order_id: orderId,
        }
    };

    return apiRequest(request);
};
/**
 * Gets all the products that belong to a service
 * @param {*} serviceId An integer that represents the id of the service
 * @returns An array with all the products of the service
 */
export const serviceProducts = (serviceId) => {
    const request = {
        url: '/v1/app/service-products',
        data: {
            service_id: serviceId,
        }
    };

    return apiRequest(request);
};

/**
 * Gets the paypal information of one client
 * @param {*} clientId An intenger that represents the id of the client
 * @returns An array with the paypal information
 */
export const paypalInfo = (clientId) => {
    const request = {
        url: '/v1/app/paypal-info',
        data: {
            client_id: clientId,
        }
    };

    return apiRequest(request);
};

/**
 * Gets the credit card information of one client
 * @param {*} clientId An intenger that represents the id of the client
 * @returns An array with the credit card information
 */
export const creditCardInfo = (clientId) => {
    const request = {
        url: '/v1/app/credit-card-info',
        data: {
            client_id: clientId,
        }
    };

    return apiRequest(request);
};

/**
 * Gets the images assigned to products, services or stores
 * @param {*} type An string with the type, this can be product, service or store
 * @param {*} ids An array with ids of the selected type or an integer
 * @returns An array with the image information
 */
export const uploadedImages = (type, ids) => {
    const request = {
        url: '/v1/app/uploaded-images',
        data: {
            type,
            ids,
        }
    };

    return apiRequest(request);
};

/**
 * Creates an order of type shop
 * @param {*} data An array with all the order data
 * @returns A string saying the order creation was succefull or an error msg
 */
export const shopping = (data) => {
    const request = {
        url: '/v1/app/shopping',
        data: {
            Order: {
                store_id: data.store,
                service_id: data.service,
                user_id: data.user,
                type_id: 2,
                state_id: data.state,
                client_id: data.client,
                room: data.room,
                products: data.products,
                request_date: data.dateTime,
            }
        }
    };

    return apiRequest(request);
};

/**
 * Creates an paypal payment
 * @param {*} data An array with all the order data
 * @returns A string saying the order creation was succefull or an error msg
 */
export const paymentPaypal = (data) => {
    const request = {
        url: '/v1/paypal/test-prepare-payment',
        data: {
            client_id: data.client_id,
            amount: data.amount,
            currency: data.currency,
            urlAppend: data.url,
        }
    };
    return apiRequest(request);
};

/**
 * Executes a paypal payment
 * @param {*} data An array with all the order data
 * @returns A string saying the order creation was succefull or an error msg
 */
export const executePaymentPaypal = (data) => {
    const request = {
        url: '/v1/paypal/test-execute-payment',
        data: {
            client_id: data.client_id,
            payer_id: data.payer_id,
            pay_id: data.pay_id
        }
    };
    return apiRequest(request);
};

/**
 * Executes a paypal payment
 * @param {*} data An array with all the order data
 * @returns A string saying the order creation was succefull or an error msg
 */
export const updateDataBasePaymentComplete = (data) => {
    const request = {
        url: '/v1/app/update-order-payment',
        data: {
            order_id: data.orderId,
        }
    };
    return apiRequest(request);
};

/**
 * Creates a credit card payment (REDUNIQ)
 * @param {*} data An array with all the order data
 * @returns A string saying the order creation was succefull or an error msg
 */
export const createPaymentCreditCard = (data) => {
    const request = {
        url: '/v1/reduniq/create-reduniq-payment',
        data: {
            client_id: data.clientId,
            username: data.username,
            password: data.password,
            amount: data.amount,
            returnURL: data.returnURL,
            clientName: data.clientName,
            buyer_email: data.buyerEmail
        }
    };
    return apiRequest(request);
};

/**
 * Creates a credit card payment (REDUNIQ)
 * @param {*} data An array with all the order data
 * @returns A string saying the order creation was succefull or an error msg
 */
export const executePaymentCreditCard = (data) => {
    const request = {
        url: '/v1/reduniq/execute-reduniq-payment',
        data: {
            client_id: data.clientId,
            username: data.username,
            password: data.password,
            token: data.token,
        }
    };
    return apiRequest(request);
};

/**
 * Gets offer menu
 * @returns array with all offers
 */
export const getOfferMenu = (data) => {
    const request = {
        url: '/v1/app/store-offer-menu',
        data: {
            client_id: data.clientId
        }
    };
    return apiRequest(request);
};

/**
 * Gets store name
 * @returns array with all offers
 */
export const getStoreName = (data) => {
    const request = {
        url: '/v1/app/store-name',
        data: {
            client_id: data
        }
    };
    return apiRequest(request);
};

/**
 * Gets activity categories
 * @returns array with all activity categories
 */
export const getActivityCategories = () => {
    const request = {
        url: '/v1/app/activity-category',
    };
    return apiRequest(request);
};

/**
 * Gets activity per category
 * @returns array with all activities of that category
 */
export const getActivityPerCategory = (data) => {
    const request = {
        url: '/v1/app/activity',
        data: {
            client_id: data.clientId,
            category: data.category
        }
    };
    return apiRequest(request);
};

/**
 * Gets events per client
 * @returns array with all events for that client
 */
export const getEvents = (data) => {
    const request = {
        url: '/v1/app/events',
        data: {
            client_id: data,
        }
    };
    return apiRequest(request);
};

/**
 * Gets bookmark per user
 * @returns array with all bookmark for that user
 */
export const getBookmark = (data) => {
    const request = {
        url: '/v1/app/bookmark',
        data: {
            user_id: data,
        }
    };
    return apiRequest(request);
};

/**
 * Gets the events on bookmark per user
 * @returns array with all bookmark for that user
 */
export const getEventsBookmark = (data) => {
    const request = {
        url: '/v1/app/event-bookmark',
        data: {
            user_id: data,
        }
    };
    return apiRequest(request);
};

/**
 * Gets the activities on bookmark per user
 * @returns array with all bookmark for that user
 */
export const getActivitiesBookmark = (data) => {
    const request = {
        url: '/v1/app/activity-bookmark',
        data: {
            user_id: data,
        }
    };
    return apiRequest(request);
};

/**
 * Add the activity on bookmark of user
 * @returns array with all bookmark for that user
 */
export const addActivtyToBookmark = (data) => {
    const request = {
        url: '/v1/app/add-activity-bookmark',
        data: {
            user_id: data.userId,
            activity: data.activity,
        }
    };
    return apiRequest(request);
};

/**
 * Add the event on bookmark of user
 * @returns array with all bookmark for that user
 */
export const addEventToBookmark = (data) => {
    const request = {
        url: '/v1/app/add-event-bookmark',
        data: {
            user_id: data.userId,
            event: data.event,
        }
    };
    return apiRequest(request);
};

/**
 * delete the activity on bookmark of user
 * @returns array with all bookmark for that user
 */
export const deleteActivityBookmark = (data) => {
    const request = {
        url: '/v1/app/delete-activity-from-bookmark',
        data: {
            user_id: data.userId,
            activity: data.activity
        }
    };
    return apiRequest(request);
};

/**
 * delete the event on bookmark of user
 * @returns array with all bookmark for that user
 */
export const deleteEventBookmark = (data) => {
    const request = {
        url: '/v1/app/delete-event-from-bookmark',
        data: {
            user_id: data.userId,
            event: data.event
        }
    };
    return apiRequest(request);
};

/**
 * Gets store working hours
 * @returns array with an array pair with the start and end hour
 */
export const getStoreWorkingHours = (storeId) => {
    const request = {
        url: '/v1/app/get-store-working-hours',
        data: {
            store_id: storeId
        }
    };
    return apiRequest(request);
};

/**
 * Checks if a store slot betwen a start hour and an end hour is available for a number of people
 * @param {*} storeId
 * @param {*} startHour
 * @param {*} endHour
 * @param {*} numberPeople
 * @returns boolean that indicates if the slot is available or not
 */
export const isTimeSlotAvailable = (storeId, startHour, endHour, numberPeople) => {
    const request = {
        url: '/v1/app/is-time-slot-available',
        data: {
            store_id: storeId,
            start_hour: startHour,
            end_hour: endHour,
            number_people: numberPeople,
        }
    };
    return apiRequest(request);
};

/**
 * Add product/service to cart
 * @returns confirmation of creation
 */
export const addToCart = (data) => {
    const request = {
        url: '/v1/app/add-to-cart',
        data: {
            user_id: data.userId,
            service_id: data.serviceId !== undefined ? data.serviceId : null,
            product_id: data.productId !== undefined ? data.productId : null,
            voucher_id: data.voucherId !== undefined ? data.voucherId : null,
        }
    };
    return apiRequest(request);
};

/**
 * Get cart information
 * @returns cart itens from user
 */
export const getCart = (data) => {
    const request = {
        url: '/v1/app/get-cart',
        data: {
            user_id: data,
        }
    };
    return apiRequest(request);
};

/**
 * Delete cart item
 * @returns confirmation of deletion
 */
export const deleteItemCart = (data) => {
    const request = {
        url: '/v1/app/delete-item-cart',
        data: {
            id: data,
        }
    };
    return apiRequest(request);
};

/**
 * Clear cart of user
 * @returns confirmation of deletion
 */
export const clearCart = (data) => {
    const request = {
        url: '/v1/app/clear-cart',
        data: {
            user_id: data,
        }
    };
    return apiRequest(request);
};

/**
 * Get cart information
 * @returns cart itens from user
 */
export const getCartItens = (data) => {
    const request = {
        url: '/v1/app/get-cart-itens',
        data: {
            user_id: data,
        }
    };
    return apiRequest(request);
};

/**
 * create shopping cart orders
 * @returns reference for orders
 */
export const shoppingCartOrders = (data) => {
    const request = {
        url: '/v1/app/shopping-cart-orders',
        data: {
            orders: data,
        }
    };
    return apiRequest(request);
};

export const getVouchers = (clientId) => {
    const request = {
        url: 'v1/app/get-vouchers',
        data: {
            client_id: clientId,
        }
    };
    return apiRequest(request);
};

export const getVoucherDetails = (voucherId) => {
    const request = {
        url: 'v1/app/get-voucher-detail',
        data: {
            voucher_id: voucherId,
        }
    };
    return apiRequest(request);
};

export const purchaseVouchers = (data) => {
    const request = {
        url: 'v1/app/save-voucher-purchases',
        data: {
            data
        }
    };
    return apiRequest(request);
};

export const getRecentOffers = (clientId, top) => {
    const request = {
        url: 'v1/app/get-recent-offers',
        data: {
            client_id: clientId,
            top
        }
    };
    return apiRequest(request);
};
