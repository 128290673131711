import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

type Props = {
    client: string,
    title: string,
    subtitle: string,
}

export default function Index(props: Props) {
    const { client, title, subtitle } = props;
    const styles = {
        textTransform: 'uppercase',
    };

    return (
        <Box component='span' display='block'>
            <Typography color='textSecondary' variant='subtitle1' style={styles}>
                {client}
            </Typography>
            <Typography variant='h5' gutterBottom>
                {title}
            </Typography>
            <Typography color='textSecondary' variant='h6' gutterBottom>
                {subtitle}
            </Typography>
        </Box>
    );
}
