import colors from '../../helpers/colors';

const styles = () => ({
    root: {
        width: '100%',
    },
    media: {
        height: 120,
        width: '100%',
    },
    btn: {
        color: colors.primary,
    }
});

export default styles;
