import colors from '../../helpers/colors';

const styles = () => ({
    available: {
        background: colors.primary,
    },
    unavailable: {
        background: colors.danger,
    },
    disabled: {
        background: colors.secondary,
    }
});

export default styles;
