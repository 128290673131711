import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import styles, { toolbarStyle } from './styles';
import RouteSelecter from './RouteSelecter';
import BottomNavigationIcon from '../BottomNavigationIcon';
import BottomNavigationLabel from '../BottomNavigationLabel';

const useStyles = makeStyles(styles);

export default function Intro() {
    const classes = useStyles();
    const [value, setValue] = React.useState('page1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <RouteSelecter value={value} />
            <BottomNavigation
                value={value}
                onChange={handleChange}
                style={toolbarStyle}
            >
                <BottomNavigationAction
                    label={(<BottomNavigationLabel text='Booking' />)}
                    value='page1'
                    icon={(
                        <BottomNavigationIcon active={value === 'page1'}>
                            <BookmarkIcon />
                        </BottomNavigationIcon>
                    )}
                    classes={classes}
                />
                <BottomNavigationAction
                    label={(<BottomNavigationLabel text='Room Service' />)}
                    value='page2'
                    icon={(
                        <BottomNavigationIcon active={value === 'page2'}>
                            <RoomServiceIcon />
                        </BottomNavigationIcon>
                    )}
                    classes={classes}
                />
                <BottomNavigationAction
                    label={(<BottomNavigationLabel text='Shopping' />)}
                    value='page3'
                    icon={(
                        <BottomNavigationIcon active={value === 'page3'}>
                            <ShoppingCartIcon />
                        </BottomNavigationIcon>
                    )}
                    classes={classes}
                />
                <BottomNavigationAction
                    label={(<BottomNavigationLabel text='Offers' />)}
                    value='page4'
                    icon={(
                        <BottomNavigationIcon active={value === 'page4'}>
                            <LocalOfferIcon />
                        </BottomNavigationIcon>
                    )}
                    classes={classes}
                />
            </BottomNavigation>
        </div>
    );
}
