import colors from '../../helpers/colors';

const styles = () => ({
    root: {
        width: '100%',
        color: colors.secondary,
    },
    media: {
        boxShadow: 'inset 0 -3em 3em rgba(0,0,0,0.7)',
    },
    h6: {
        fontWeight: 'bold',
    },
    color: {
        color: colors.primary,
    }
});

export default styles;
