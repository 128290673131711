import React from 'react';

type Props = {
    text: string,
}

export default function Index(props: Props) {
    const { text } = props;
    const styles = {
        fontSize: '9px',
        fontWeight: '400',
        textTransform: 'uppercase',
    };
    return (
        <div style={styles}>
            {text}
        </div>
    );
}
