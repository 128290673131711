import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';
import Box from '@material-ui/core/Box';

import BottomTabNavigation from './components/BottomTabNavigation';
import Events from './pages/Events';
import NotificationPage from './pages/Notifications';
import RecentOffers from './pages/RecentOffers';
import OffersAndVouchers from './pages/OffersAndVouchers';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/recent-offers/:room/:clientId/:userId'>
                    <RecentOffers />
                </Route>
                <Route path='/offers/:room/:clientId/:userId'>
                    <Box p={3} pb={10}>
                        <OffersAndVouchers title='Offers And Vouchers' />
                    </Box>
                </Route>
                <Route path='/events/:clientId/:userId'>
                    <Events />
                </Route>
                <Route path='/notifications/:id'>
                    <NotificationPage />
                </Route>
                <Route path='/:room/:clientId/:userId'>
                    <BottomTabNavigation />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
