import React, { Component } from 'react';
import 'fontsource-roboto';
import Box from '@material-ui/core/Box';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Grid from '@material-ui/core/Grid';
import LoadingIndicator from '../../components/LoadingIndicator';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import BookmarkText from '../../components/BookmarkText';
import ActivityCategoryCard from '../../components/ActivityCategoryCard';
import EventCard from '../../components/EventCard';
import ActivityDetails from '../ActivityDetails';
import EventDetails from '../EventDetails';

import {
    getEventsBookmark,
    getActivitiesBookmark,
    getActivityCategories,
    deleteActivityBookmark,
    deleteEventBookmark
} from '../../api/services';

type Props = {
}
export default class index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            selectedActivity: false,
            selectedEvents: false,
            item: '',
            apiDataActivityCategory: false,
            apiDataActivityBookmark: false,
            userId: 0,
            apiDataEventBookmark: false,
        };
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const userId = array[array.length - 1];
        this.setState({ userId });
        getActivityCategories().then((response) => {
            this.setState({ apiDataActivityCategory: response });
        });
        getEventsBookmark(userId).then((response) => {
            this.setState({ apiDataEventBookmark: response });
        });
        getActivitiesBookmark(userId).then((response) => {
            this.setState({ apiDataActivityBookmark: response });
        });
    }

    handleActivtyDetail(selectedItem) {
        this.setState({ selectedActivity: true });
        this.setState({ item: selectedItem });
    }

    handleEventDetail() {
        this.setState({ selectedEvents: true });
    }

    deleteActivityFromBookmark = (item) => {
        const {
            userId
        } = this.state;
        const data = { userId, activity: item.id };
        deleteActivityBookmark(data).then(() => {
            getActivitiesBookmark(data.userId).then((response) => {
                this.setState({ apiDataActivityBookmark: response });
            });
        });
    }

    deleteEventFromBookmark = (item) => {
        const {
            userId
        } = this.state;
        const data = { userId, event: item.id };
        deleteEventBookmark(data).then(() => {
            getEventsBookmark(data.userId).then((response) => {
                this.setState({ apiDataEventBookmark: response });
            });
        });
    }

    activitycategoryCreate = (apiDataActivityCategory) => {
        const result = [];
        if (apiDataActivityCategory) {
            apiDataActivityCategory.forEach((item) => {
                result.push(
                    <GridListTile key={item.id} style={{ width: '40%' }}>
                        <ActivityCategoryCard
                            item={item}
                            handleDetail={() => { this.handleActivtyDetail(item); }}
                        />
                    </GridListTile>

                );
            });
        }
        return result;
    }

    comeBackActivity = () => {
        this.setState({ selectedActivity: false });
        const {
            userId
        } = this.state;
        getActivitiesBookmark(userId).then((response) => {
            this.setState({ apiDataActivityBookmark: response });
        });
    }

    comeBackEvent = () => {
        this.setState({ selectedEvents: false });
        const {
            userId
        } = this.state;
        getEventsBookmark(userId).then((response) => {
            this.setState({ apiDataEventBookmark: response });
        });
    }

    bookmarkCreate = (apiDataActivityBookmark, apiDataEventBookmark) => {
        const result = [];
        if (apiDataActivityBookmark) {
            apiDataActivityBookmark.forEach((item) => {
                result.push(
                    <BookmarkText
                        id={item.id}
                        title={item.date}
                        subtitle={item.title}
                        deleteBookmark={() => { this.deleteActivityFromBookmark(item); }}
                    />
                );
            });
        }
        if (apiDataEventBookmark) {
            apiDataEventBookmark.forEach((item) => {
                result.push(
                    <BookmarkText
                        id={item.id}
                        title={item.date}
                        subtitle={item.title}
                        deleteBookmark={() => { this.deleteEventFromBookmark(item); }}
                    />
                );
            });
        }
        return result;
    }

    render() {
        const {
            apiDataActivityCategory,
            apiDataActivityBookmark,
            apiDataEventBookmark,
            selectedActivity,
            selectedEvents,
            item
        } = this.state;

        if (selectedActivity && item !== '') {
            return (

                <ActivityDetails
                    goBack={() => { this.comeBackActivity(); }}
                    item={item}
                />
            );
        }
        if (selectedEvents) {
            return (
                <EventDetails
                    goBack={() => { this.comeBackEvent(); }}
                    item={item}
                />
            );
        }
        return (
            apiDataActivityCategory && apiDataActivityBookmark && apiDataEventBookmark ? (
                <div>
                    <ServicesHeadTitle client='' title='Activities' />
                    <Box>
                        <Box p={1}>
                            {' '}
                            <div style={{
                                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', overflow: 'hidden',
                            }}
                            >
                                <GridList spacing={20} style={{ flexWrap: 'nowrap' }}>
                                    {this.activitycategoryCreate(apiDataActivityCategory)}

                                </GridList>
                            </div>
                        </Box>
                        <Box p={1}>
                            <ServicesHeadTitle client='Cabanas Nature' title='Events' />
                            <Grid
                                onClick={() => { this.handleEventDetail(); }}
                            >
                                <EventCard title='Events' description='Lots of events to happen here' image='turtle_event.jpg' />
                            </Grid>
                        </Box>
                        <Box p={1}>
                            <ServicesHeadTitle client='My all' title='Bookmarks' />
                            {this.bookmarkCreate(apiDataActivityBookmark, apiDataEventBookmark)}
                        </Box>
                    </Box>

                </div>
            ) : <LoadingIndicator />
        );
    }
}
