/**
 * App Default Colors
 */
const colors = {

    /**
     * Colors
     */
    backgroundColor: 'whitesmoke',
    primary: '#92b096',
    secondary: '#ffffff',
    danger: '#cf463c',
    disabled: '#7d7d7d',
};

export const chartColors = {
    red: 'rgb(229, 37, 234)',
    orange: 'rgb(240, 173, 78)',
    yellow: 'rgb(245, 168, 17)',
    green: 'rgb(15, 215, 147)',
    blue: 'rgb(121, 121, 255)',
    purple: 'rgb(97, 61, 124)',
    grey: 'rgb(136,136,136)'
};

export default colors;
