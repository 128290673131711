import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import 'fontsource-roboto';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'universal-cookie';
import LoadingIndicator from '../../components/LoadingIndicator';
import Card from '../../components/Cards';
import Store from '../Stores';
import ServicesHeadTitle from '../../components/ServicesHeadTitle';
import {
    stores,
    executePaymentPaypal,
    creditCardInfo,
    updateDataBasePaymentComplete,
    executePaymentCreditCard
} from '../../api/services';
import imgRest from '../../res/img/room-service.jpg';
import imgSpa from '../../res/img/spa.jpg';
import imgOther from '../../res/img/bulle31.jpg';

type Props = {
    title: string,
    access: string
}

export default class index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            selected: false,
            apiData: false,
            key: '',
            popUp: false,
            popUpMessage: '',
            returnClientUrl: ''
        };
        this.images = {
            'Restaurants & Bars': imgRest,
            'Spas & Wellness': imgSpa,
            Other: imgOther,
        };
    }

    componentDidMount() {
        const cookies = new Cookies();
        const clientId = cookies.get('storeID');
        const array = window.location.href.split('/');
        const client = array[array.length - 2];
        const { access } = this.props;
        stores(client, access).then((response) => {
            this.setState({ apiData: response });
        });
        if (window.location.href.includes('paymentId')) {
            const purchase = window.location.href.split(/[&=]+/);
            // console.log(purchase);
            const data = { client_id: clientId, payer_id: purchase[5], pay_id: purchase[1] };
            executePaymentPaypal(data).then((response) => {
                this.setState({ popUp: true });
                if (response.state === 'approved') {
                    const creditCardData = { client_id: cookies.get('storeID') };
                    creditCardInfo(creditCardData).then((creditCardResponse) => {
                        const returnUrl = creditCardResponse.return_url;
                        this.setState({ returnClientUrl: returnUrl });
                    });
                    this.setState({ popUpMessage: 'Payment completed, your order is complete.' });
                    // change order state
                    const orderInfo = { orderId: cookies.get('orderID') };
                    updateDataBasePaymentComplete(orderInfo);
                } else {
                    this.setState({ popUpMessage: 'Unable to complete payment, proceed to reception to complete order.' });
                }
            });
        }
        if (window.location.href.includes('token')) {
            const token = window.location.href.split(/[&=]+/);
            // console.log(token[1]);
            creditCardInfo(clientId).then((response) => {
                const data = {
                    client_id: clientId,
                    username: response.reduniq_username,
                    password: response.reduniq_password,
                    token: token[1],
                };
                executePaymentCreditCard(data).then((reduniqResponse) => {
                    const n = reduniqResponse.indexOf('code');
                    const str = reduniqResponse.substring(n);
                    const substr = str.split('>')[1];
                    if (substr.split('<')[0] === '10000000') {
                        this.setState({ returnClientUrl: response.return_url });
                        this.setState({ popUpMessage: 'Payment completed, your order is complete.' });
                        // change order state
                        const orderInfo = { orderId: cookies.get('orderID') };
                        updateDataBasePaymentComplete(orderInfo);
                    } else {
                        this.setState({ popUpMessage: 'Unable to complete payment, proceed to reception to complete order.' });
                    }
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { title } = this.props;
        if (title !== prevProps.title) {
            this.selector(false, '');
        }
    }

    selector(selected, key) {
        this.setState({ selected, key });
    }

    renderItems = (apiData) => {
        const result = [];
        Object.keys(apiData).forEach((key) => (
            result.push(
                <Grid
                    item
                    key={key}
                    xs={12}
                    md={6}
                    onClick={() => { this.selector(true, key); }}
                >

                    <Card
                        title={key}
                        description={apiData[key].description}
                        image={apiData[key].image}
                        type='storeTypes'
                    />
                </Grid>
            )
        ));

        return result;
    }

    render() {
        const {
            apiData, selected, key, popUp, popUpMessage, returnClientUrl
        } = this.state;
        const { title } = this.props;

        if (selected) {
            return (
                <Store
                    apiData={apiData[key].stores}
                    title={`${key} ${title}`}
                    goBack={() => this.setState({ selected: false })}
                    type={title}
                />
            );
        }

        return (
            apiData ? (
                <div>
                    <ServicesHeadTitle client='Cabanas Nature Resort' title={title} />
                    <Box>
                        <div>
                            <Dialog
                                open={popUp}
                                onClose={() => { this.selector(false, popUp); }}
                                aria-labelledby='alert-dialog-title'
                                aria-describedby='alert-dialog-description'
                            >
                                <DialogTitle id='alert-dialog-title'>Payment Status</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id='alert-dialog-description'>
                                        {popUpMessage}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => { window.location.replace({ returnClientUrl }); }} color='primary'>
                                        Ok
                                    </Button>

                                </DialogActions>
                            </Dialog>
                        </div>
                        <Grid
                            container
                            justify='center'
                            alignItems='center'
                            spacing={2}
                        >
                            {this.renderItems(apiData)}
                        </Grid>
                    </Box>

                </div>
            ) : <LoadingIndicator />
        );
    }
}
