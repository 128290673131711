import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styles from './styles';

const useStyles = makeStyles(styles());

type Props = {
    clickHandler: Function,
}

export default function Index(props: Props) {
    const classes = useStyles();
    const { clickHandler } = props;
    return (
        <Button
            variant='contained'
            color='secondary'
            className={classes.root}
            onClick={clickHandler}
        >
            Checkout
        </Button>
    );
}
