/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import 'fontsource-roboto';
import LoadingIndicator from '../../components/LoadingIndicator';
import OfferCard from '../../components/OfferCard';
import OffersItens from '../OffersItens';
import { getOfferMenu } from '../../api/services';

type Props = {
    handleCartLength: Function,
    handleShowGoBack: Function,
    show: Boolean,
    handleShow: Function
}

export default class Index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            data: [],
            apiData: false,
            selected: false,
            idStart: 0,
            idEnd: 0,
            shop: 0,
        };
        this.menuCreate = this.menuCreate.bind(this);
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const clientId = array[array.length - 2];
        const clientInfo = { clientId };
        getOfferMenu(clientInfo).then((response) => {
            this.setState({ apiData: response });
        });
    }

    menuCreate = (data) => {
        const result = [];
        const shapedData = this.shapeOfferMenu(data);
        const { handleShow } = this.props;
        if (data) {
            if (shapedData.length > 0) {
                let index = 0;
                shapedData.forEach((offer) => {
                    result.push(
                        <Grid
                            item
                            key={index}
                            xs={12}
                            md={6}
                            onClick={() => {
                                this.selector(offer.idStart, offer.idEnd, offer.store);
                                handleShow(true);
                            }}
                        >
                            <OfferCard
                                title={offer.title}
                                storeId={offer.store}
                                description={offer.description}
                                image={offer.image}
                                discount={offer.maxDiscount}
                                idStart={offer.idStart}
                                idEnd={offer.idEnd}

                            />
                        </Grid>
                    );
                    index += 1;
                });
            }
            if (result.length === 0) {
                return (
                    <Typography>
                        No offers available
                    </Typography>
                );
            }
            return result;
        }
        return (
            <LoadingIndicator />
        );
    }

    maxDiscount = (data, idStart, idEnd) => {
        let result = 0;
        for (let i = 0; i < data.length; i += 1) {
            if (data[i].id >= idStart && data[i].id <= idEnd) {
                if (data[i].type === 3 || data[i].type === 5 || data[i].type === 8) {
                    if (result < data[i].discount) result = data[i].discount;
                }
            }
        }
        return result;
    }

    shapeOfferMenu = (data) => {
        const result = [];
        let index = 0;
        let newBlock = true;
        for (let i = 0; i < data.length; i += 1) {
            if (newBlock) {
                result.push({});
                result[index].idStart = data[i].id;
                newBlock = false;
            }
            if (data[i].type === 1) {
                result[index].title = data[i].value;
                result[index].store = data[i].store_id;
                if (data[i].image != null) result[index].image = data[i].image;
                else result[index].image = '';
            }
            if (data[i].type === 2) {
                result[index].description = data[i].value;
            }
            if (data[i].type === 4 || i === data.length - 1) {
                result[index].idEnd = data[i].id;
                result[index].maxDiscount = this.maxDiscount(
                    data,
                    result[index].idStart,
                    result[index].idEnd
                );
                index += 1;
                newBlock = true;
            }
        }
        return result;
    }

    selector(start, end, shopId) {
        this.setState({ selected: true });
        this.setState({ idStart: start });
        this.setState({ idEnd: end });
        this.setState({ shop: shopId });
    }

    render() {
        const {
            apiData, selected, idStart, idEnd, shop
        } = this.state;
        const { handleCartLength, handleShowGoBack, show } = this.props;
        if (selected && show) {
            return (
                <OffersItens
                    shop={shop}
                    idStart={idStart}
                    idEnd={idEnd}
                    handleCartLength={handleCartLength}
                    handleShowGoBack={handleShowGoBack}
                />
            );
        }

        return (
            apiData ? this.menuCreate(apiData) : <LoadingIndicator />
        );
    }
}
