/* eslint-disable no-unused-vars */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import styles from './styles';
import imgRest from '../../res/img/spa.jpg';

const useStyles = makeStyles(styles());
type Props = {
    title: string,
    description: string,
    image: string,
    type: String,
    roomService: Boolean,
    price: Number,
}

function isRoomService(roomService: Boolean, price:Number,) {
    return (
        roomService ? (
            <Box pt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant='body2'>
                            {price}
                            {' '}
                            $
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        ) : null
    );
}

function getImage(image, type) {
    if (image === '' || image === null) return imgRest;
    return `https://media.mtihotel.pt/${type}/${image}`;
}

export default function CardSelect(props: Props) {
    const {
        title, description, image, roomService, price, type
    } = props;
    const classes = useStyles();
    return (
        <Card
            className={classes.root}
        >

            <CardMedia
                image={getImage(image, type)}
                className={classes.media}
            >

                <CardContent>
                    <CardActionArea>
                        <Typography variant='h6' className={classes.h6}>
                            {title}
                        </Typography>
                        <Typography variant='body2'>
                            {description}
                        </Typography>
                    </CardActionArea>
                    {isRoomService(roomService, price)}

                </CardContent>

            </CardMedia>

        </Card>
    );
}
