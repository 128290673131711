import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import { getVouchers } from '../../api/services';
import LoadingIndicator from '../../components/LoadingIndicator';
import VoucherCard from '../../components/VoucherCard';
import VoucherDetail from '../VoucherDetail';

type Props = {
    handleCartLength: Function,
    handleShowGoBack: Function,
    show: Boolean,
    handleShow: Function
}

export default class index extends Component<Props> {
    constructor() {
        super();
        this.state = {
            voucherData: [],
            voucherSelected: false,
        };
    }

    componentDidMount() {
        const array = window.location.href.split('/');
        const clientId = array[array.length - 2];
        getVouchers(clientId).then((response) => {
            this.setState({ voucherData: response });
        });
    }

    renderVouchers(voucherData: Array) {
        const result = [];
        const { handleShow } = this.props;
        voucherData.forEach((val) => {
            result.push(
                <Grid
                    item
                    key={val.id}
                    xs={12}
                    md={6}
                    onClick={() => {
                        this.setState({ voucherSelected: val.id });
                        handleShow(true);
                    }}
                >
                    <VoucherCard
                        name={val.name}
                        storeName={val.store}
                        discount={val.discount}
                        price={val.price}
                        image={val.image.length > 0 ? val.image[0] : ''}
                    />
                </Grid>
            );
        });
        return result;
    }

    render() {
        const { voucherData, voucherSelected } = this.state;
        const { handleShowGoBack, handleCartLength, show } = this.props;

        if (voucherSelected && show) {
            return (
                <Grid
                    item
                    xs={12}
                    md={12}
                >
                    <VoucherDetail
                        voucherId={voucherSelected}
                        handleCartLength={handleCartLength}
                        handleShowGoBack={handleShowGoBack}
                    />
                </Grid>

            );
        }

        if (voucherData.length > 0) {
            // voucher view
            return this.renderVouchers(voucherData);
        }
        return (<LoadingIndicator />);
    }
}
