/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import LoadingIndicator from '../../components/LoadingIndicator';
import OfferItemCard from '../../components/OfferItemCard';
import OfferServiceCard from '../../components/OfferServiceCard';
import StoreItemDetail from '../StoreItemDetail';
import StoreCheckout from '../StoreCheckout';
import {
    storeProducts, storeServices, getOfferMenu, getCart, addToCart
} from '../../api/services';

type Props = {
    shop: number,
    idStart: number,
    idEnd: number,
    handleCartLength: Function,
    handleShowGoBack: Function,
}
export default class Index extends Component<Props> {
    constructor() {
        super();

        this.state = {
            selected: false,
            checkout: false,
            item: '',
            apiDataProducts: false,
            apiDataServices: false,
            apiDataOffer: [],
            userId: 0,
        };
    }

    componentDidMount() {
        const { shop, handleShowGoBack } = this.props;
        handleShowGoBack();

        storeProducts(shop).then((response) => {
            this.setState({ apiDataProducts: response });
        });
        storeServices(shop).then((response) => {
            this.setState({ apiDataServices: response });
        });
        const array = window.location.href.split('/');
        const clientId = array[array.length - 2];
        const clientInfo = { clientId };
        const userId = array[array.length - 1];
        this.setState({ userId });
        getOfferMenu(clientInfo).then((response) => {
            this.setState({ apiDataOffer: response });
        });
    }

    getOfferProducts = (data) => {
        const { idStart, idEnd } = this.props;
        const result = [];
        data.forEach((offer) => {
            if (offer.type === 3 && offer.id >= idStart && offer.id <= idEnd) {
                result.push(offer.product_id);
            }
        });
        return result;
    }

    getOfferServices = (data) => {
        const { idStart, idEnd } = this.props;
        const result = [];
        data.forEach((offer) => {
            if (offer.type === 5 && offer.id >= idStart && offer.id <= idEnd) {
                result.push(offer.service_id);
            }
        });
        return result;
    }

    addToCart(item: Array, type: String) {
        this.setState({ selected: false });
        const { userId } = this.state;
        const { handleCartLength } = this.props;
        let data;
        if (type === 'service') data = { userId, serviceId: item.id, productId: null };
        else if (type === 'product') data = { userId, serviceId: null, productId: item.id };
        addToCart(data).then(() => {
            getCart(userId).then((response) => {
                handleCartLength(response.length);
                // this.setState({ cartLength: response.length });
            });
        });
    }

    renderItems = (apiDataProducts: Array, apiDataServices: Array, apiDataOffer: Array) => {
        const offerProducts = this.getOfferProducts(apiDataOffer);
        const offerServices = this.getOfferServices(apiDataOffer);
        const result = [];
        if (apiDataProducts) {
            apiDataProducts.forEach((item) => {
                if (offerProducts.includes(item.id)) {
                    result.push(
                        <Grid
                            item
                            key={`p-${item.id}`}
                            xs={6}
                            md={6}
                        >
                            <OfferItemCard
                                item={item}
                                image={item.image}
                                addToCart={() => { this.addToCart(item, 'product'); }}
                            />
                        </Grid>
                    );
                }
            });
        }
        if (apiDataServices) {
            apiDataServices.forEach((item) => {
                if (offerServices.includes(item.id)) {
                    result.push(
                        <Grid
                            item
                            key={`s-${item.id}`}
                            xs={6}
                            md={6}
                        >
                            <OfferServiceCard
                                item={item}
                                image={item.image}
                                addToCart={() => { this.addToCart(item, 'service'); }}
                            />
                        </Grid>
                    );
                }
            });
        }
        return (
            <Grid container spacing={1}>{result}</Grid>
        );
    }

    render() {
        const {
            selected, item, checkout, apiDataProducts, apiDataServices, apiDataOffer,
        } = this.state;

        return (
            apiDataServices ? this.renderItems(apiDataProducts, apiDataServices, apiDataOffer)
                : <LoadingIndicator />
        );
    }
}
