import React from 'react';
import { Button as MaterialBtn } from '@material-ui/core';
import i18n from '../../i18n';
import colors from '../../helpers/colors';

const Button = (props: Object) => {
    const { text, backgroundColor, onClick } = props;

    return (
        <MaterialBtn
            onClick={onClick}
            size='large'
            type='submit'
            variant='contained'
            width={500}
            color='primary'
            style={{
                width: '100%',
                backgroundColor: backgroundColor || colors.primary
            }}
        >
            {i18n.t(text || 'SUBMIT')}
        </MaterialBtn>
    );
};

export default Button;
