import React from 'react';

import styles from './styles';

type Props = {
    active: boolean,
    children: any
}

const activeChild = (children: any) => (
    <span>
        {children}
    </span>
);

export default function Index(props: Props) {
    const { active, children } = props;

    if (active) {
        return React.cloneElement(
            activeChild(children),
            { style: styles }
        );
    }
    return (
        <div>
            {children}
        </div>
    );
}
