import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

type Props = {
    id: Number,
    title: string,
    date: string,
    description: string,
    location: string,
    saveBookmark: Function,

}

export default function Index(props: Props) {
    const {
        title, date, description, location, saveBookmark, id
    } = props;
    const styles = {
        textTransform: 'uppercase',
    };

    return (
        <Box component='span' display='block' p={1}>
            <Button
                size='small'
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    saveBookmark(id);
                }}
            >
                Add to Bookmark
            </Button>
            <Typography color='textSecondary' variant='subtitle1' style={styles}>
                {date}
            </Typography>
            <Typography variant='h5' style={{ fontWeight: 600 }}>
                {title}
            </Typography>
            <Typography variant='h6'>
                {description}
                {' '}
            </Typography>
            <Typography>
                {location}
            </Typography>
        </Box>
    );
}
