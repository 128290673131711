import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import imgRest from '../../res/img/spa.jpg';

import styles from './styles';

const useStyles = makeStyles(styles());

type Props = {
    item: Array,
    addToCart: Function,
    handleDetail: Function,
    roomService: Boolean,
}

function roomServiceCheck(roomService, item) {
    const qty = item.quantity === 'undefined' ? 0 : item.quantity;

    if (roomService) {
        return (
            <Typography>
                { `Qty: ${qty}` }
            </Typography>
        );
    }

    return (
        <Typography>
            { `${item.price} $` }
        </Typography>
    );
}

function cartBtnShow(roomService, item, addToCart) {
    const classes = useStyles();

    return (!roomService ? (
        <Button
            size='small'
            className={classes.btn}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                if (typeof addToCart !== 'undefined') {
                    addToCart(item);
                }
            }}
        >
            Add to Cart
        </Button>
    ) : null);
}

function getImage(image, type) {
    if (image === '' || image === null) return imgRest;
    return `https://media.mtihotel.pt/${type}/${image}`;
}

function itemName(name) {
    const result = [];
    if (name.length <= 50) {
        result.push(
            <Typography>
                {name}
            </Typography>
        );
    } else if (name.length > 50 && name.lenght < 80) {
        result.push(
            <Typography style={{ fontSize: '1em' }}>
                {name}
            </Typography>
        );
    } else {
        result.push(
            <Typography style={{ fontSize: '0.9em' }}>
                {name}
            </Typography>
        );
    }
    return result;
}

export default function index(props: Props) {
    const {
        item, addToCart, handleDetail, roomService
    } = props;
    const classes = useStyles();
    const img = item.image.length > 0 ? item.image[0] : '';
    return (
        <Card className={classes.root}>
            <CardActionArea
                onClick={() => {
                    if (typeof handleDetail !== 'undefined') {
                        handleDetail(true, item);
                    }
                }}
            >
                <CardMedia
                    image={getImage(img, 'products')}
                    className={classes.media}
                />
                <CardContent className={classes.content}>
                    {itemName(item.name)}
                    {roomServiceCheck(roomService, item)}
                </CardContent>
            </CardActionArea>
            <CardActions>
                {cartBtnShow(roomService, item, addToCart)}
            </CardActions>
        </Card>

    );
}
