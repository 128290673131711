import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import imgRest from '../../res/img/spa.jpg';
import styles from './styles';
import colors from '../../helpers/colors';
import Button from '../Button';

const useStyles = makeStyles(styles());

type Props = {
    data: Array,
    addToCart: Function,
}

function getImage(image) {
    if (image === '' || image === null) return imgRest;
    return `https://media.mtihotel.pt/vouchers/${image}`;
}

function renderProducts(products) {
    const result = [];
    if (products.length === 0) {
        return '';
    }

    products.forEach((val) => {
        result.push(
            <Grid item xs={12}>
                <Typography variant='subtitle2' gutterBottom>
                    {`${val.quantity} x ${val.name}` }
                </Typography>
            </Grid>
        );
    });
    return (
        <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
                Products
            </Typography>
            <Grid container spacing={0}>
                {result}
            </Grid>
        </Grid>
    );
}

function renderServices(services) {
    const result = [];
    if (services.length === 0) {
        return '';
    }

    services.forEach((val) => {
        result.push(
            <Grid item xs={12}>
                <Typography variant='subtitle2' gutterBottom>
                    {`${val.quantity} x ${val.name}` }
                </Typography>
            </Grid>
        );
    });
    return (
        <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
                Services
            </Typography>
            <Grid container spacing={0}>
                {result}
            </Grid>
        </Grid>
    );
}

export default function index(props:Props) {
    const { data, addToCart } = props;
    const classes = useStyles();

    if (data.length === 0) {
        return 'No values found';
    }
    const dateArr = data.expires.split('-');
    const newFormatDate = `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
    return (
        <>
            <Box mb={3}>
                <Card className={classes.root}>
                    <CardMedia
                        image={data.image.length > 0 ? getImage(data.image[0]) : getImage('')}
                        className={classes.media}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h5' gutterBottom>
                                    {data.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant='h6' gutterBottom>
                                    {`${data.price}€ `}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='h6' gutterBottom style={{ color: colors.primary }}>
                                    {`-${data.discount}%`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2' gutterBottom>
                                    {`Voucher expires at: ${newFormatDate}`}
                                </Typography>
                            </Grid>
                            {renderProducts(data.products)}
                            {renderServices(data.services)}
                            <Grid item xs={12}>
                                <Typography variant='h6' gutterBottom>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2' gutterBottom>
                                    {data.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6' gutterBottom>
                                    Conditions
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2' gutterBottom>
                                    {data.condition}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Box mb={2}>
                <Button
                    text='Add to Cart'
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (typeof addToCart !== 'undefined') {
                            addToCart();
                        }
                    }}
                />
            </Box>
        </>
    );
}
