import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Typography } from '@material-ui/core';
import imgRest from '../../res/img/spa.jpg';

import styles from './styles';

const useStyles = makeStyles(styles());

type Props = {
    item: Array,
    handleDetail: Function,
}

function getImage(image) {
    let result;
    try {
        result = `https://media.mtihotel.pt/ActivityCategory/${image}`;
    } catch (e) {
        result = imgRest;
    }
    return result;
}

export default function index(props: Props) {
    const {
        item, handleDetail,
    } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea
                onClick={() => {
                    if (typeof handleDetail !== 'undefined') {
                        handleDetail(item.id);
                    }
                }}
            >
                <CardMedia
                    image={item.image != null ? getImage(item.image) : imgRest}
                    className={classes.media}
                />
                <CardContent className={classes.content}>
                    <Typography style={{ fontWeight: 600 }}>
                        { item.name }
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>

    );
}
